import Carousel from 'react-multi-carousel'
import 'react-multi-carousel/lib/styles.css'
import { Link } from 'react-router-dom'
import useWidth from '../hooks/useWidth'
import { LazyLoadImage } from 'react-lazy-load-image-component';

const responsive = {
	desktop: {
		breakpoint: { max: 3000, min: 768 },
		items: 3,
		slidesToSlide: 3, // optional, default to 1.
	},
	mobile: {
		breakpoint: { max: 768, min: 0 },
		items: 1,
		slidesToSlide: 1, // optional, default to 1.
	},
}



export default function Collection({ collectionData, headline }) {
	const width = useWidth()

	const items = collectionData?.length > 0 && collectionData?.map(data => (
		<div className="px-md-3 d-inline-block mx-2 mx-md-0" key={data.id}>
			<Link to={`/collection/${data.slug}`}>
				<div style={{ opacity: 1, transition: 'opacity 100s ease-in 0s' }}>
					<div>
						<span
							className="lazy-load-image-background  lazy-load-image-loaded"
							style={{
								display: 'inline-block',
								height: 'auto',
								width: '100%',
							}}
						>
							<img
								alt={data.name}
								src={data.image_path}
								className="d-block img-fluid cursor"
								width="100%"
								height="auto"
								title={data.name}
								style={
									width <= 768
										? {
											width: 320,
											height: 215,
											borderRadius: '5%',
										}
										: {
											width: '38rem',
											height: '24rem',
											borderRadius: '1%',
										}
								}
							/>
						</span>
					</div>
				</div>
			</Link>
		</div>
	))

	return (
		<div>
			<div className="my-3">
				{width >= 768 ? <h2>{headline}</h2> : <h5 className="text-center">{headline}</h5>}
			</div>
			<div className={`${width <= 768 ? 'hotDealsCarousel' : ''}`}>
				{width >= 768 ? (
					<Carousel responsive={responsive} arrows={width >= 768}>
						{items}
					</Carousel>
				) : (
					<div className="auto-carousel-x">{items}</div>
				)}
			</div>
		</div>
	)
}
