import filterIcon from '../images/filterIcon.png'
import useFilters from '../hooks/useFilters'
import { useWebsite } from "../context/WebsiteContext";
import { useState, useEffect } from 'react'

export default function Filters({ setFilterDataRequest }) {
	// const { loading, error, filters } = useFilters();
	const { setProductFilter, productFilter, filters } = useWebsite();

	const handleFilterCheckbox = (event, name) => {
		if (event.target.checked) {
			setProductFilter(oldName => [...oldName, name]);
		}
		else {
			setProductFilter(prev => prev.filter(filter => filter !== name))
		}
	}


	return (
		<div className="px-3 filterDropdown">
			<span>
				<img src={filterIcon} style={{ fontSize: '15px' }} alt='Filter Icon' />
			</span>
			<span className="px-1 cursor position-relative">Filters</span>
			<div className="filterDropDownContent">
				<div>
					<div
						className="d-flex justify-content-between px-2 py-2"
						style={{ fontSize: '13px', fontWeight: 'bold' }}
					>
						<div>Smart Filters</div>
						<div style={{ cursor: 'pointer' }}>Reset all</div>
					</div>
					<div className="border-top" />
					<div className="px-2 py-2 filterScroll">
						{filters && filters?.length > 0 && (
							<>
								<div className="d-flex justify-content-between bg-light" style={{ color: 'gray' }}>
									{filters?.map((filterCategory, i) => (
										<div className="px-1 py-2 col-2" key={i}>
											<u> {filterCategory.name} </u>
										</div>
									))}
								</div>
								<div className="d-flex justify-content-between filtersCheckbox">
									{filters?.map((filterCategory, i) => (
										<div className="col-2" key={i}>
											{filterCategory?.filter_types?.length > 0 && filterCategory.filter_types?.map((filterType, id) => (
												<div className="d-flex" key={id}>
													<div className="px-1 py-1">
														<input
															type="checkbox"
															id={`${filterType.id}-product_type`}
															name={`${filterType.id}-product_type`}
															style={{
																backgroundColor: 'rgb(197, 26, 68)',
															}}
															onClick={e => handleFilterCheckbox(e, filterType.name)}
														/>
													</div>
													<label
														id={`${filterType.id}-product_type`}
														className="text-capitalize pt-1 filterText cursor"
													>
														{filterType.name}
													</label>
												</div>
											))}
										</div>
									))}
								</div>
							</>
						)}
					</div>
				</div>
				<div className="d-flex justify-content-center py-2 applyFilters cursor" onClick={() => setFilterDataRequest((previousValue) => previousValue + 1)}>Apply Filter(s)</div>
			</div>
		</div>
	)
}
