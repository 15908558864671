import image from "../images/image1x1.jpg";
import image1 from "../images/image1x1-1.jpg";
import Offerblog from "../components/OfferBlog";
import { useState, useEffect } from "react";
import Footer from "../components/Footer";
import MBFooter from "../components/MBFooter";
import useWidth from "../hooks/useWidth";
import BackNavbar from "../components/BackNavbar";
import Navbar from "../components/Navbar";
import axios from "../lib/axiosConfig";
import {
  isLoggedInCheck,
  callToast,
  setValueLocalStorage,
  getValueLocalStorage,
} from "../lib/functions";
import { useWebsite } from "../context/WebsiteContext";
import MBNavbar from "../components/MBNavbar";
import { Link, useLocation, useNavigate } from "react-router-dom";

import {
  ErrorMessage,
  FastField,
  Field,
  FieldArray,
  Form,
  Formik,
} from "formik";
import * as Yup from "yup";
import useAddProduct from "../hooks/useAddProduct";
import ReactPixel from "react-facebook-pixel";
import { useCart } from "../context/CartContext";
import { useWishlist } from "../context/WishlistContext";

// Initialize ReactPixel with your Pixel ID
ReactPixel.init("540109202031009");

const initialValues = {
  email: "",
  password: "",
};

export default function Login() {
  const { handleProductIntoCart, handleProductIntoWishlist } = useAddProduct();
  const {
    websiteInfo,
    setIsLoggedInState,
    isLoggedInState,
    setUserInfo,
    setTotalCartQuantity,
    setTotalCartAmount,
    cartDispatch,
    setWishlist,
    setWishlistCount,
  } = useWebsite();
  const width = useWidth();
  let navigate = useNavigate();
  const [emailCustomError, setEmailCustomError] = useState("");
  const [passwordCustomError, setPasswordCustomError] = useState("");
  const [cartProduct, setCartProduct] = useState([]);
  const { carts, setCarts } = useCart();
  const { setWishlists, wishlists } = useWishlist();

  useEffect(() => {
    ReactPixel.pageView(); // For tracking page view
  }, []);

  const loadUser = async () => {
    var loggedToken = getValueLocalStorage();
    await axios({
      method: "post",
      url: "user",
      headers: { Authorization: `Bearer ${loggedToken}` },
    })
      .then((response) => {
        ReactPixel.track('Lead', { content_name: 'Logged in user' });
        setIsLoggedInState(true);
        setUserInfo(response.data.user);
        setTotalCartQuantity(response.data.cart.cartProductQuantity);
        setTotalCartAmount(response.data.cart.cartProductAmount);
        setWishlist(response.data.wishlist.wishlistProducts);
        setWishlistCount(response.data.wishlist.wishlistCount);

        cartDispatch({
          type: "SET_CART",
          payload: response.data.cart.cartProduct,
        });

        return navigate("/cart");
      })
      .catch((err) => {
        setIsLoggedInState(false);
        callToast("warn", "Wow so easy!");
      });
  };

  useEffect(() => {
    if (isLoggedInCheck()) {
      loadUser();
    }
  }, []);

  const validationSchema = Yup.object({
    email: Yup.string()
      .email("Invalid email format")
      .required("Required Email Address!"),
    password: Yup.string().required("Required Name !"),
  });

  const onSubmit = async (values, onSubmitProps) => {
    await axios({
      method: "post",
      url: "login-user",
      data: values,
    })
      .then(async (response) => {
        // Wrap the code block inside an async function
        setIsLoggedInState(true);
        setUserInfo(response.data.user);
        callToast("success", "Your successfully login");

        localStorage.setItem("isLoggedIn", true);
        if (response.data && response.data.token) {
          setValueLocalStorage(response.data.token);
        }
        setWishlist(response.data.wishlist.wishlistProducts);
        setWishlistCount(response.data.wishlist.wishlistCount);

        setTotalCartQuantity(response.data.cart.cartProductQuantity);
        setTotalCartAmount(response.data.cart.cartProductAmount);

        cartDispatch({
          type: "SET_CART",
          payload: response.data.cart.cartProducts,
        });

        console.log("logged in");



        var loggedToken = await getValueLocalStorage("loggedToken");

        if (carts.length > 0) {
          for (const item of carts) {
            await handleProductIntoCart(item?.product, item?.selectedColor);
          }
          setCarts([]);
          localStorage.removeItem("cartItem");
        }

        // let wishlistItems = localStorage.getItem("wishlistItem");
        // if (wishlistItems) {
        //   try {
        //     wishlistItems = JSON.parse(wishlistItems);
        //   } catch (error) {
        //     console.error("Error parsing wishlist items:", error);
        //     wishlistItems = [];
        //   }
        // } else {
        //   wishlistItems = [];
        // }

        if (wishlists.length > 0) {
          for (const item of wishlists) {
            await handleProductIntoWishlist(item?.product, item?.selectedColor);
          }
          setWishlists([]);
          localStorage.removeItem("wishlistItem");
        }

        const forgotPass = localStorage.getItem("forgotPass");

        if (forgotPass) {
          localStorage.removeItem("forgotPass");
          return navigate("/cart");
        } else {
          return navigate(-1);
        }
      })
      .catch((err) => {
        callToast("error", "Invalid Credential");

        setIsLoggedInState(false);

        localStorage.setItem("isLoggedIn", false);
        localStorage.setItem("loggedToken", "");
        setEmailCustomError("");
        setPasswordCustomError("");

        if (err.response && err.response.data.errors) {
          err.response.data.errors.forEach((item, index) => {
            if (item.code === "email") {
              setEmailCustomError(item.message);
            }
            if (item.code === "password") {
              setPasswordCustomError(item.message);
            }
          });
        }
      });
  };

  return (
    <>
      {width >= 768 ? <Navbar /> : <MBNavbar searchButton={false} />}

      <div className="mt-4 mx-3 bg-white" style={{ borderRadius: "20px" }}>
        <div className="row pt-2 container-fluid">
          <div
            className="offset-md-3 col-md-5 col-lg-5 my-2 bg-white shadow-lg py-5 px-5 mb-4 mx-auto"
            style={{ borderRadius: "20px" }}
          >
            <section id="section-basic-example">
              <img
                src={websiteInfo.photo_path}
                title="BD Beauty Glamorous"
                alt="BD Beauty Glamorous"
                style={{
                  // position: "absolute",
                  // marginTop: "-10px",
                  width: "150px",
                  height: "60px",
                }}
                className="d-flex justify-content-center text-center mx-auto"
              />
              <h2 className="mb-4 text-center">Login</h2>
              <section className="pb-4">
                <Formik
                  initialValues={initialValues}
                  validationSchema={validationSchema}
                  onSubmit={onSubmit}
                  validateOnChange={false}
                  validateOnBlur={false}
                >
                  {(formik) => {
                    return (
                      <Form>
                        <div className="mb-3">
                          <label htmlFor="email">Number or E-mail</label>
                          <Field
                            type="text"
                            id="email"
                            name="email"
                            placeholder="Number or E-mail"
                            className="form-control"
                          />
                          <ErrorMessage name="email">
                            {(msg) => <div className="error">{msg}</div>}
                          </ErrorMessage>
                          {emailCustomError !== "" && (
                            <div className="error">{emailCustomError}</div>
                          )}
                        </div>
                        <div className="mb-3">
                          <label htmlFor="password">password</label>
                          <Field
                            type="password"
                            id="password"
                            name="password"
                            placeholder="Password"
                            className="form-control"
                          />
                          <ErrorMessage name="password">
                            {(msg) => <div className="error">{msg}</div>}
                          </ErrorMessage>
                          {passwordCustomError !== "" && (
                            <div className="error">{passwordCustomError}</div>
                          )}
                        </div>
                        <div className="d-grid">
                          <button type="submit" className="btn btn-primary">
                            Submit
                          </button>
                        </div>
                      </Form>
                    );
                  }}
                </Formik>
                <Link
                  to={`/forgot-password`}
                  className="mt-2 d-flex justify-content-center"
                >
                  Forgotten Password?
                </Link>
              </section>
              {/* {width <= 768 && ( */}
              <div className="text-center mt-5">
                <p>
                  Enjoy Special Deals, <Link to={`/registration`}>Sign up</Link>{" "}
                  to Get at First.
                </p>
              </div>
              {/* )} */}
            </section>
          </div>
        </div>
      </div>

      {width >= 768 ? <Footer /> : <MBFooter />}
    </>
  );
}
