import { useEffect, useState } from "react";
import axios from "../lib/axiosConfig";
import { callToast } from "../lib/functions";
import { useNavigate } from "react-router-dom";
import { useWebsite } from "../context/WebsiteContext";
import { getValueLocalStorage } from "../lib/functions";
import { useCart } from "../context/CartContext";

import ReactPixel from "react-facebook-pixel";
import { useWishlist } from '../context/WishlistContext';

// Initialize ReactPixel with your Pixel ID
ReactPixel.init("540109202031009");

const useAddProduct = () => {
  const navigate = useNavigate();
  const { carts, handleAddToCart } = useCart();
  const { wishlists, handleAddToWishlist } = useWishlist();

  const {
    cartDispatch,
    isLoggedInState,
    setTotalCartQuantity,
    setTotalCartAmount,
    setWishlist,
    setWishlistCount,
  } = useWebsite();

  // const updateLocalStorage = (newItem) => {
  //   const updatedCart = [...cart, newItem];
  //   setCart(updatedCart);
  //   localStorage.setItem('cartItem', JSON.stringify(updatedCart));
  // };

  const handleProductIntoCart = async (product, selectedColor = null) => {
    var loggedToken = getValueLocalStorage("loggedToken");

    // if (isLoggedInState === true) {
    if (loggedToken) {

      try {
        await axios({
          method: "post",
          url: "add-to-cart",
          headers: { Authorization: `Bearer ${loggedToken}` },
          data: {
            product_id: product.id,
            quantity: 1,
            sku_id: selectedColor ? selectedColor.id : null,
          },
        })
          .then((response) => {
            ReactPixel.pageView();
            ReactPixel.track("AddToCart");
            ReactPixel.track('Lead', { content_name: 'Added to cart' });
            callToast("success", response.data.message);
            cartDispatch({
              type: "SET_CART",
              payload: response.data.cart.cartProducts,
            });
            setTotalCartQuantity(response.data.cart.cartProductQuantity);
            setTotalCartAmount(response.data.cart.cartProductAmount);
          })
          .catch((err) => {
            callToast("warn", "Product Add into Error");
          });
      } catch (error) {
        callToast("warn", "Product Add into Error");
      }
    }
    else {

      const newItem = {
        product: product,
        quantity: 1,
        selectedColor: selectedColor ? selectedColor : null,
        price: parseInt(product?.discount_amount) > 0 ? parseInt(product?.sale_rate) - parseInt(product?.discount_amount) : parseInt(product?.sale_rate),
      };
      handleAddToCart(newItem);

    }


  };

  const handleProductIntoWishlist = async (product, selectedColor = null) => {
    var loggedToken = getValueLocalStorage("loggedToken");
    if (loggedToken) {
      try {
        await axios({
          method: "post",
          url: "add-to-wishlist",
          headers: { Authorization: `Bearer ${loggedToken}` },
          data: {
            product_id: product.id,
            quantity: 1,
            sku_id: selectedColor ? selectedColor.id : null,
          },
        })
          .then((response) => {
            callToast("success", response.data.message);
            setWishlist(response.data.wishlist.wishlistProducts);
            setWishlistCount(response.data.wishlist.wishlistCount);
          })
          .catch((err) => {
            callToast("warn", "Product Add into Error");
          });
      } catch (error) {
        callToast("warn", "Product Add into Error");
      }
    }
    else {


      const newItem = {
        product: product,
        quantity: 1,
        selectedColor: selectedColor ? selectedColor : null,
      };
      handleAddToWishlist(newItem);

    }
  };

  return {
    handleProductIntoCart,
    handleProductIntoWishlist,
  };
};

export default useAddProduct;
