import Bereadcrumb from "../components/Bereadcrumb";
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';

import Footer from "../components/Footer";
import Navbar from "../components/Navbar";
import CustomerSideBar from "../components/CustomerSideBar";
import { useWebsite } from "../context/WebsiteContext";
import { Link, useParams } from "react-router-dom";
import Table from 'react-bootstrap/Table';
import { useEffect, useState } from "react";
import axios from "../lib/axiosConfig";
import { numberWithSpaces, getValueLocalStorage, isEmpty } from '../lib/functions';

function OrderDetail() {
	const { userInfo } = useWebsite();
	const [loading, setLoading] = useState(true);
	const [error, setError] = useState("");
	const [orderInfo, setOrderInfo] = useState([]);

	let { orderId } = useParams();

	useEffect(() => {
		async function fetchOrders() {
			var loggedToken = getValueLocalStorage('loggedToken');
			try {
				await axios({
					method: "post",
					url: "order-detail",
					data: { id: orderId },
					headers: { Authorization: `Bearer ${loggedToken}` },
				})
					.then((response) => {
						setOrderInfo(response.data.order);
						setLoading(false);
						setError(false);
					})
					.catch((err) => {
						setOrderInfo([]);
						setLoading(false);
						setError(err);
					});
			} catch (error) {
				setLoading(false);
				setError("Connection Error");
			}
		}

		fetchOrders();

		return () => {
			setOrderInfo([]);
			setError("");
			setLoading(true);
		};
	}, []);


	return (
		<>
			<Navbar />
			<div style={{ overflowX: "hidden" }} className="pb-3">
				<div>
					<Bereadcrumb pages={[{ path: "/orders", name: "Order" }]} />
					<div
						className="shadow-lg mt-4 mx-3 bg-white d-flex justify-content-center"
						style={{ borderRadius: "20px" }}
					>
						<div className="row container-fluid">
							<div className="col-12 col-lg-2 px-4 ">
								<section className="mt-3">
									<CustomerSideBar />
								</section>
							</div>
							<div className="col-12 col-lg-10 py-3">
								<section>
									<div className="d-flex justify-content-between cart_orderSummary">
										<div>
											<span>
												<h6>
													<span className="px-2">
														Order
													</span>
												</h6>
											</span>
										</div>
									</div>
								</section>
								<div
									style={{
										background: "#faf9f9",
										borderRadius: "10px",
									}}
									className="p-2 row"
								>
									{(orderInfo && !isEmpty(orderInfo)) && (
										<>
											<div className="col-md-6">
												<Card >
													<Card.Body>
														<Card.Text>
															<div className="row">
																<div className="col-md-6">
																	<h4>{orderInfo.order_address.full_name}</h4>
																	<div>{orderInfo.order_address.address}</div>
																	<div>{orderInfo.order_address.contact_number}</div>
																</div>
																<div className="col-md-6 text-end">
																	<h4>No: {orderInfo.order_no}</h4>
																	<div>Status: {orderInfo.order_status}</div>
																	<div>Quantity : {orderInfo.bill_quantity}</div>
																	<div>Amount : {numberWithSpaces(orderInfo.bill_amount)}</div>
																</div>
															</div>
															{(orderInfo.order_logs && orderInfo.order_products.length) && (
																<Table striped hover>
																	<thead>
																		<tr>
																			<th className="text-center">#</th>
																			<th className="text-center">Product </th>
																			<th className="text-center">Quantity</th>
																			<th className="text-center">Price</th>
																			<th className="text-center">Amount</th>
																		</tr>
																	</thead>
																	<tbody>
																		{orderInfo.order_products?.map((order_product, iteration) => (
																			<tr key={order_product.id}>
																				<td className="text-center">{iteration + 1}</td>
																				<td className="text-center">{order_product.product.name}</td>
																				<td className="text-center">{order_product.quantity}</td>
																				<td className="text-center">{numberWithSpaces(order_product.price)}</td>
																				<td className="text-center">{numberWithSpaces(order_product.price * order_product.quantity)}</td>
																			</tr>
																		))}

																	</tbody>
																</Table>
															)}
														</Card.Text>
													</Card.Body>
												</Card>

											</div>
											<div className="col-md-6">
												<div className="timeline">
													{(orderInfo.order_logs && orderInfo.order_logs.length > 0) && (orderInfo.order_logs?.map((orderLog, iteration) => (
														<div className={(iteration + 1) % 2 === 0 ? `timelineContainer right` : `timelineContainer left`} key={orderLog.id}>
															<div className="timelineContent">
																<h4>{orderLog.order_status}</h4>
																<p>{orderLog.date_time}</p>
															</div>
														</div>
													)))}
												</div>
											</div>
										</>
									)}


								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<Footer />
		</>
	);
}

export default OrderDetail;
