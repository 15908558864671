import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import { useState } from "react";
import Stories from "react-insta-stories";
import StoryData from "./StoryData";
import Container from "react-bootstrap/Container";
import { useWebsite } from "../context/WebsiteContext";
import { Link } from "react-router-dom";

export default function MBStories() {
  const [isClose, setIsClose] = useState(true);
  const toggleClose = () => setIsClose(!isClose);

  const { categories } = useWebsite();

  const stories = [
    {
      content: (props) => <StoryData props={props} handler={toggleClose} />,
    },
    {
      content: (props) => <StoryData props={props} handler={toggleClose} />,
    },
    {
      content: (props) => <StoryData props={props} handler={toggleClose} />,
    },
  ];

  return (
    <Container>
      <div className="stories-swiper mt-2">
        {!isClose && (
          <div className="story-preview">
            <Stories
              stories={stories}
              defaultInterval={6000}
              width="100vw"
              height="100vh"
              className="position-absolute"
            />
          </div>
        )}

        <Swiper spaceBetween={24} slidesPerView={4}>
          {categories?.map((category) => (
            <SwiperSlide key={Math.random()}>
              <div onClick={toggleClose}>
                <Link to={`/${category?.slug}`}>
                  <div className="stories-list-anchor">
                    <div className="stories-container">
                      <img
                        src={category?.image_path}
                        alt=""
                        style={{ borderRadius: "50%" }}
                      />
                    </div>
                  </div>
                  <span className="sg-title">{category?.name}</span>
                </Link>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </Container>
  );
}
