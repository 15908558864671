import axios from 'axios';
import React, { useEffect, useState } from 'react';
import useWidth from '../hooks/useWidth';

const TopTitle = ({ title }) => {
    const width = useWidth();

    return (
        <div style={{ backgroundColor: "black", color: "white", padding: "5px 0" }}>
            {
                width <= 768 ? (
                    <p className='fw-bold mb-0 text-center' style={{fontSize: "12px"}}>{title}</p>
                ) : (
                    <p className='fw-bold mb-0 text-center'>{title}</p>
                )
            }

        </div>
    );
};

export default TopTitle;