import Bereadcrumb from "../components/Bereadcrumb";
import { useState, useEffect } from "react";
import withCart from "../HOCs/withCart";
import Footer from "../components/Footer";
import Navbar from "../components/Navbar";
import CustomerSideBar from "../components/CustomerSideBar";
import { useWebsite } from "../context/WebsiteContext";
import { Link, useNavigate } from "react-router-dom";
import { callToast, getValueLocalStorage } from "../lib/functions";

import axios from "../lib/axiosConfig";
import {
  ErrorMessage,
  FastField,
  Field,
  FieldArray,
  Form,
  Formik,
} from "formik";
import * as Yup from "yup";
import MBNavbar from "../components/MBNavbar";

const initialValues = {
  current_password: "",
  new_password: "",
};

function ChangerPassword() {
  const { userInfo } = useWebsite();
  const navigate = useNavigate();

  const [currentPasswordCustomError, setCurrentPasswordCustomError] =
    useState("");
  const [newPasswordCustomError, setNewPasswordCustomError] = useState("");

  const validationSchema = Yup.object({
    current_password: Yup.string()
      .required("Required Current Password !")
      .min(6)
      .nullable(),
    new_password: Yup.string()
      .required("Required New Password !")
      .min(6)
      .nullable(),
  });

  const onSubmit = async (values, onSubmitProps) => {
    var loggedToken = getValueLocalStorage();

    await axios({
      method: "post",
      url: "password-update",
      headers: { Authorization: `Bearer ${loggedToken}` },
      data: values,
    })
      .then((response) => {
        callToast("success", response.data.message);
        return navigate("/profile");
      })
      .catch((err) => {
        setCurrentPasswordCustomError("");
        setNewPasswordCustomError("");
        if (err && err.response && err.response.data.errors) {
          err.response.data.errors.forEach((item, index) => {
            if (item.code === "current_password") {
              setCurrentPasswordCustomError(item.message);
            }
            if (item.code === "new_password") {
              setNewPasswordCustomError(item.message);
            }
          });
        } else {
          callToast("error", err.response.data.error);
        }
      });
  };

  return (
    <>
      {/* <Navbar /> */}
      <MBNavbar searchButton={false} />
      <div style={{ overflowX: "hidden" }} className="pb-3">
        <div>
          <Bereadcrumb pages={[{ path: "/cart", name: "Cart" }]} />
          <div
            className="shadow-lg mt-4 mx-3 bg-white d-flex justify-content-center"
            style={{ borderRadius: "20px" }}
          >
            <div className="row container-fluid">
              <div className="col-12 col-lg-4 px-4 ">
                <section className="mt-3">
                  <CustomerSideBar />
                </section>
              </div>
              <div className="col-12 col-lg-8 py-3">
                <section>
                  <div className="d-flex justify-content-between cart_orderSummary">
                    <div>
                      <span>
                        <h6>
                          <span className="px-2">Update Password</span>
                        </h6>
                      </span>
                    </div>
                  </div>
                </section>
                <div
                  style={{
                    background: "#faf9f9",
                    borderRadius: "10px",
                  }}
                  className="p-2 "
                >
                  {userInfo && (
                    <Formik
                      initialValues={initialValues}
                      validationSchema={validationSchema}
                      onSubmit={onSubmit}
                      validateOnChange={false}
                      validateOnBlur={false}
                    >
                      {(formik) => {
                        return (
                          <Form>
                            <div className="mb-3">
                              <label htmlFor="current_password">
                                Current Password
                              </label>
                              <Field
                                type="password"
                                id="current_password"
                                name="current_password"
                                className="form-control"
                              />
                              <ErrorMessage name="current_password">
                                {(msg) => <div className="error">{msg}</div>}
                              </ErrorMessage>
                              {currentPasswordCustomError !== "" && (
                                <div className="error">
                                  {currentPasswordCustomError}
                                </div>
                              )}
                            </div>
                            <div className="mb-3">
                              <label htmlFor="new_password">New Password</label>
                              <Field
                                type="password"
                                id="new_password"
                                name="new_password"
                                className="form-control"
                              />
                              <ErrorMessage name="new_password">
                                {(msg) => <div className="error">{msg}</div>}
                              </ErrorMessage>
                              {newPasswordCustomError !== "" && (
                                <div className="error">
                                  {newPasswordCustomError}
                                </div>
                              )}
                            </div>
                            <div className="d-grid">
                              <button type="submit" className="btn btn-primary">
                                Update
                              </button>
                            </div>
                          </Form>
                        );
                      }}
                    </Formik>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default ChangerPassword;
