import image from "../images/image1x1.jpg";
import image1 from "../images/image1x1-1.jpg";
import Offerblog from "../components/OfferBlog";
import { useEffect, useState } from "react";
import Footer from "../components/Footer";
import MBFooter from "../components/MBFooter";
import useWidth from "../hooks/useWidth";
import BackNavbar from "../components/BackNavbar";
import Navbar from "../components/Navbar";
import axios from "../lib/axiosConfig";
import { isLoggedInCheck, callToast } from "../lib/functions";
import MBNavbar from "../components/MBNavbar";


import {
  ErrorMessage,
  FastField,
  Field,
  FieldArray,
  Form,
  Formik,
} from "formik";
import * as Yup from "yup";

import { useNavigate } from "react-router-dom";
import ReactPixel from "react-facebook-pixel";

// Initialize ReactPixel with your Pixel ID
ReactPixel.init("540109202031009");

const initialValues = {
  name: "",
  email: "",
  password: "",
  contactNumber: "",
  address: "",
};

export default function Registration() {
  const width = useWidth();
  const navigate = useNavigate();
  const [nameCustomError, setNameCustomError] = useState("");
  const [emailCustomError, setEmailCustomError] = useState("");
  const [passwordCustomError, setPasswordCustomError] = useState("");
  const [contactNumberCustomError, setContactNumberCustomError] = useState("");
  const [addressCustomError, setAddressCustomError] = useState("");
  const [isLoadingBtn, setIsLoadingBtn] = useState(false);

  useEffect(() => {
    ReactPixel.pageView(); // For tracking page view
    ReactPixel.track('Lead', { content_name: 'Home Page' });
  }, []);

  const validationSchema = Yup.object({
    name: Yup.string().required("Required Name!"),
    contact_number: Yup.string().required("Required Contact Number!"),
    email: Yup.string()
      .email("Invalid email format"),
    // .required("Required Email Address!"),
    password: Yup.string().required("Required Password !").min(6).nullable(),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref("password"), null], "Passwords must match")
      .required("Required Confirm Password!"),
    address: Yup.string().required("Required Address!").nullable(),
  });

  const onSubmit = async (values, onSubmitProps) => {
    // console.log("Submit Props", onSubmitProps);
    // onSubmitProps.setSubmitting(false);

    setIsLoadingBtn(true);
    await axios({
      method: "post",
      url: "registration-user",
      data: values,
    })
      .then((response) => {
        callToast("success", response.data.message);
        setIsLoadingBtn(false);
        ReactPixel.track('Lead', { content_name: 'Registered User' });
        return navigate("/login");
      })
      .catch((err) => {
        console.log("err", err);
        setNameCustomError("");
        setEmailCustomError("");
        setPasswordCustomError("");
        setContactNumberCustomError("");
        setIsLoadingBtn(false);
        if (err && err.response && err.response.data.errors) {
          err.response.data.errors.forEach((item, index) => {
            if (item.code === "name") {
              setNameCustomError(item.message);
            }
            if (item.code === "contact_number") {
              setContactNumberCustomError(item.message);
            }
            if (item.code === "email") {
              setEmailCustomError(item.message);
            }
            if (item.code === "password") {
              setPasswordCustomError(item.message);
            }
            if (item.code === "address") {
              setAddressCustomError(item.message);
            }
          });
        } else {
          callToast("error", err.response.data.message);
        }
      });
  };

  return (
    <>
      {width >= 768 ? (
        <Navbar />
      ) : (
        <BackNavbar backurl="/" title="Registration" />
      )}
      {/* {width >= 768 ? <Navbar /> : <MBNavbar searchButton={false} />} */}

      <div className="pb-5 pb-md-0">
        <div className="row pt-2 container-fluid bg-white pe-0 pb-3 pb-md-0">
          <div
            className="offset-md-3 col-md-5 col-lg-5 my-2 bg-white shadow-lg py-5 px-5 mb-4 mx-auto"
            style={{ borderRadius: "20px" }}
          >
            <section id="section-basic-example">
              <h2 className="mb-4 text-center">Registration</h2>
              <section className="pb-4">
                <Formik
                  initialValues={initialValues}
                  validationSchema={validationSchema}
                  onSubmit={onSubmit}
                  //   validateOnChange={false}
                  //   validateOnBlur={false}
                  validateOnMount
                >
                  {(formik) => {
                    return (
                      <Form>
                        <div className="mb-3">
                          <label htmlFor="name">Name</label>
                          <Field
                            type="text"
                            id="name"
                            name="name"
                            className="form-control"
                          />
                          {formik.touched.name && formik.errors.name && (
                            <div className="error">{formik.errors.name}</div>
                          )}
                          {nameCustomError !== "" && (
                            <div className="error">{nameCustomError}</div>
                          )}
                        </div>
                        <div className="mb-3">
                          <label htmlFor="contact_number">Contact Number</label>
                          <Field
                            type="text"
                            id="contact_number"
                            name="contact_number"
                            className="form-control"
                          />
                          <ErrorMessage name="contact_number">
                            {(msg) => <div className="error">{msg}</div>}
                          </ErrorMessage>
                          {contactNumberCustomError !== "" && (
                            <div className="error">
                              {contactNumberCustomError}
                            </div>
                          )}
                        </div>

                        <div className="mb-3">
                          <label htmlFor="address">Address</label>
                          <Field
                            type="text"
                            id="address"
                            name="address"
                            className="form-control"
                          />
                          <ErrorMessage name="address">
                            {(msg) => <div className="error">{msg}</div>}
                          </ErrorMessage>
                          {addressCustomError !== "" && (
                            <div className="error">{addressCustomError}</div>
                          )}
                        </div>

                        <div className="mb-3">
                          <label htmlFor="email">Email</label>
                          <Field
                            type="text"
                            id="email"
                            name="email"
                            className="form-control"
                          />
                          {/* <ErrorMessage name="email">
                            {(msg) => <div className="error">{msg}</div>}
                          </ErrorMessage>
                          {emailCustomError !== "" && (
                            <div className="error">{emailCustomError}</div>
                          )} */}
                        </div>

                        <div className="mb-3">
                          <label htmlFor="password">password</label>
                          <Field
                            type="password"
                            id="password"
                            name="password"
                            className="form-control"
                          />
                          <ErrorMessage name="password">
                            {(msg) => <div className="error">{msg}</div>}
                          </ErrorMessage>
                          {passwordCustomError !== "" && (
                            <div className="error">{passwordCustomError}</div>
                          )}
                        </div>
                        <div className="mb-3">
                          <label htmlFor="confirmPassword">Confirm Password</label>
                          <Field
                            type="password"
                            id="confirmPassword"
                            name="confirmPassword"
                            className="form-control"
                          />
                          <ErrorMessage name="confirmPassword">
                            {(msg) => <div className="error">{msg}</div>}
                          </ErrorMessage>
                          {/* {confirmPasswordCustomError !== "" && (
                            <div className="error">{confirmPasswordCustomError}</div>
                          )} */}
                        </div>

                        <div className="d-grid">
                          <button
                            type="submit"
                            className="btn btn-primary"
                            disabled={isLoadingBtn || formik.values.password !== formik.values.confirmPassword}
                          >
                            {isLoadingBtn ? "Processing..." : "Submit"}
                          </button>
                        </div>
                      </Form>
                    );
                  }}
                </Formik>
              </section>
            </section>
          </div>
        </div>
      </div>

      {width >= 768 ? <Footer /> : <MBFooter />}
    </>
  );
}
