import React, { createContext, useState, useEffect, useContext } from 'react';
import { toast } from 'react-toastify';

const WishlistContext = createContext();

export const useWishlist = () => {
    return useContext(WishlistContext);
};

export const WishlistProvider = ({ children }) => {
    const [wishlists, setWishlists] = useState([]);

    useEffect(() => {
        // Load wishlist from localStorage on initial render
        const wishlistsFromLocalStorage = JSON.parse(localStorage.getItem('wishlistItem')) || [];
        setWishlists(wishlistsFromLocalStorage);
    }, []);

    const handleAddToWishlist = (newItem) => {
        const updatedWishlist = [...wishlists];
        const existingProductIndex = updatedWishlist?.findIndex(item => item?.product?.id === newItem?.product?.id);

        if (existingProductIndex !== -1) {
            updatedWishlist[existingProductIndex].quantity += newItem.quantity;
        } else {
            updatedWishlist.push(newItem);
        }

        toast.success('Product added to wishlist');
        setWishlists(updatedWishlist);
        localStorage.setItem('wishlistItem', JSON.stringify(updatedWishlist));
    };

    const handleUpdateWishlistQuantity = (product, type) => {

        const updatedWishlist = wishlists?.map((item) => {
            var quantity = type === "increment" ? 1 : -1;
            if (item?.product?.id === product?.id) {
                if (item?.quantity > 1 || type === "increment") {
                    toast.success("Product quantity updated successfully");
                    return { ...item, quantity: item.quantity + quantity };
                }
                else {
                    toast.error("Quantity can't be less than 1");
                }
            }
            return item;
        });

        setWishlists(updatedWishlist);
        localStorage.setItem('wishlistItem', JSON.stringify(updatedWishlist));
    };

    const handleRemoveFromWishlist = (product) => {
        const updatedWishlist = wishlists.filter(item => item.product.id !== product.id);

        toast.success('Product removed from wishlist');
        setWishlists(updatedWishlist);
        localStorage.setItem('wishlistItem', JSON.stringify(updatedWishlist));
    };


    return (
        <WishlistContext.Provider value={{ wishlists, handleAddToWishlist, handleUpdateWishlistQuantity, handleRemoveFromWishlist, setWishlists }}>
            {children}
        </WishlistContext.Provider>
    );
};
