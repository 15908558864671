import CollectionTitle from '../components/CollectionTitle'
import LoadingSpinner from '../components/LoadingSpinner'
import Product from '../components/Product'
import Navbar from '../components/Navbar'
import Footer from '../components/Footer'
import Bereadcrumb from '../components/Bereadcrumb'
import ProductsHeadline from '../components/ProductsHeadline'
import { useParams, useLocation } from 'react-router-dom';
import InfiniteScroll from 'react-infinite-scroll-component'
import { useState, useEffect } from "react";
import axios from "../lib/axiosConfig";
import { Helmet } from "react-helmet";
import { isEmpty } from '../lib/functions'
import { useWebsite } from "../context/WebsiteContext";
import ReactPixel from "react-facebook-pixel";
import MessengerCustomerChat from "react-messenger-customer-chat";

// Initialize ReactPixel with your Pixel ID
ReactPixel.init("540109202031009");


export default function Products() {

	const { setProductFilter, productFilter } = useWebsite();
	const [page, setPage] = useState(1);
	const [loading, setLoading] = useState(true);
	const [error, setError] = useState("");
	const [products, setProducts] = useState([]);
	const [categoryInfo, setCategoryInfo] = useState({});
	const [pageProducts, setPageProducts] = useState([]);
	const [totalResult, setTotalResult] = useState(0);
	const [filterDataRequest, setFilterDataRequest] = useState(0);
	const [hasMore, setHasMore] = useState(true);

	useEffect(() => {
		ReactPixel.pageView(); // For tracking page view
	}, []);


	let { categorySlug, subcategorySlug } = useParams();
	let getLocalProducts = JSON.parse(localStorage.getItem('categories'));

	let category = getLocalProducts && getLocalProducts.length > 0 && getLocalProducts.find(cat => categorySlug === cat.slug);


	let pages = category ? [{ name: category.name, path: `/${categorySlug}` }] : [{ name: 'products', path: `products` }];

	let pageName = category ? category.name : "";
	if (category) {
		if (subcategorySlug && category.sub_categories.length > 0) {
			let subCategory = category.sub_categories.find(subCat => subcategorySlug === subCat.slug);
			pages = [{ name: category.name, path: `/${categorySlug}` }, { name: subCategory.name, path: `/${categorySlug}/${subcategorySlug}` }];
			pageName = subCategory.name;
		}
	}

	async function fetchProducts() {
		try {
			await axios({
				method: "get",
				url: "all-products",
				params: {
					page: page,
					categorySlug: categorySlug,
					subcategorySlug: subcategorySlug,
					filter: productFilter,
				}
			})
				.then((response) => {
					setProducts(preProduct => {
						return [...new Set([...preProduct, ...response.data.data.products])]
					});
					// setProducts(response.data.data);
					setCategoryInfo(response.data.data.category);
					setTotalResult(response.data.total_result);
					setHasMore(response.data.has_more);
					setLoading(false);
					setError(false);
				})
				.catch((err) => {
					setProducts([]);
					setCategoryInfo({});
					setTotalResult(0);
					setLoading(false);
					setError(err);
				});
		} catch (error) {
			console.log('Fetch data error');
		}
	}
	useEffect(() => {
		fetchProducts();
		return () => {
			setProducts([]);
			setCategoryInfo({});
			setTotalResult(0);
			setError("");
			setLoading(true);
		};
	}, [categorySlug, subcategorySlug, page]);

	useEffect(() => {
		if (filterDataRequest !== 0) {
			setPage(1);
			setPageProducts([]);
			setProducts([]);
			setTotalResult(0);
			fetchProducts();
			setError("");
			setLoading(true);
		}
	}, [filterDataRequest]);


	useEffect(() => {
		setPageProducts(prePageProduct => {
			return [...new Set([...prePageProduct, ...products])]
		});
	}, [products]);


	const location = useLocation();

	useEffect(() => {
		setPage(1);
		setPageProducts([]);
		setProductFilter([]);

		return () => {
			setPageProducts([]);
			setProductFilter([]);
			setPage(1);
		};
	}, [location]);




	return (
		<>

			{!isEmpty(categoryInfo) ? (
				<>
					<Helmet>
						<title>{categoryInfo.name}</title>
						<meta name="description" content={categoryInfo.meta_description} />
						<meta name="keywords" content={categoryInfo.meta_keywords} />
						<meta name="author" content={categoryInfo.meta_author} />
					</Helmet>
				</>
			) : (
				<>
					<Helmet>
						<title>All  Product  </title>
					</Helmet>
				</>
			)}

			<Navbar />
			<div className="px-3">
				<CollectionTitle
					render={() => (
						<Bereadcrumb pages={pages} fontWeight="600" />
					)}
					renderInfo={() => <ProductsHeadline name={pageName} items={totalResult} />}
					setFilterDataRequest={setFilterDataRequest}
				/>
				<div className="container">
					{pageProducts.length > 0 ? (
						<InfiniteScroll
							dataLength={pageProducts.length}
							hasMore={hasMore}
							next={() => setPage(page + 1)}
							loader={<LoadingSpinner />}
						>
							<div className="d-flex flex-wrap">
								{pageProducts?.map((product, pi) => (
									<Product key={pi} product={product} />
								))}
							</div>
						</InfiniteScroll>
					) : (
						<>
							{loading && !error && (<div><LoadingSpinner /></div>)}
						</>
					)}


					{!loading && pageProducts.length === 0 && (<div> No Data found</div>)}

					{loading && error && (<div> There is an error</div>)}

				</div>

			</div>
			<MessengerCustomerChat
				pageId=""
				appId=""
			/>
			<Footer />
		</>
	)
}
