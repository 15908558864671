/* eslint-disable react/jsx-no-target-blank */
import { FaTiktok } from "react-icons/fa";
import logo from "../images/logo1x1.jpg";
import { Link } from "react-router-dom";
import playstoreIcon from "../images/playstore.png";
import appleStoreIcon from "../images/apple-store.png";
import phone_android_whiteIcon from "../images/phone_android_white.svg";
import common from "../common.json";
import { useWebsite } from "../context/WebsiteContext";
import { callToast } from "../lib/functions";
import axios from "../lib/axiosConfig";
import {
  GrFacebookOption,
  GrInstagram,
  GrPinterest,
  GrTumblr,
  GrYoutube,
} from "react-icons/gr";
import { useEffect, useState } from "react";
import ReactPixel from "react-facebook-pixel";
import useWidth from "../hooks/useWidth";
import { FaFacebook, FaInstagram, FaYoutube } from "react-icons/fa";

import facebook from '../images/FaceBook.png';
import instagram from '../images/Instagram.png';
import youtube from '../images/youtube.png';
import tiktok from '../images/tiktok.png';

// Initialize ReactPixel with your Pixel ID
ReactPixel.init("540109202031009");

const currentDate = new Date();
const currentYear = currentDate.getFullYear();

export default function Footer() {
  const { websiteInfo } = useWebsite();
  const [subscribeEmail, setSubscribeEmail] = useState("");
  const width = useWidth();
  useEffect(() => {
    ReactPixel.track("Lead", { content_name: "Newsletter" });
  }, []);

  const handleSubscribe = async () => {
    await axios({
      method: "post",
      url: "subscribe-email",
      data: {
        email: subscribeEmail,
      },
    })
      .then((response) => {
        callToast("success", "Your successfully Subscribe");
      })
      .catch((err) => {
        if (err.response.status === 401) {
          callToast("error", err.response.data.errors[0].message);
        } else {
          callToast("error", "Subscribe Failed");
        }
      });
  };

  return (
    <footer>
      <div className="mt-2">
        <footer className="footer_main px-5">
          <div className="pt-4 px-2">
            <div className="row">
              <div className="col-md-2 col-12 mt-3" style={{ color: "white" }}>
                <div
                  style={{
                    textAlign: "center",
                  }}
                >
                  <Link to="/">
                    <img
                      src={websiteInfo.photo_path}
                      style={{ backgroundColor: "#fff", padding: "10px" }}
                      width="150px"
                      height="100px"
                      alt={websiteInfo.name}
                      className="m-2"
                    />
                  </Link>
                  {/* <div>
                    <span className="px-1">
                      <a href="#" target="_blank">
                        <GrFacebookOption fontSize={20} color="white" />
                      </a>
                    </span>
                    <span className="px-1">
                      <a href="#" target="_blank">
                        <GrInstagram fontSize={20} color="white" />
                      </a>
                    </span>
                    <span className="px-1">
                      <a href="#" target="_blank">
                        <GrPinterest fontSize={20} color="white" />
                      </a>
                    </span>
                    <span className="px-1">
                      <a href="#" target="_blank">
                        <GrTumblr fontSize={20} color="white" />
                      </a>
                    </span>
                    <span className="px-1">
                      <a href="#" target="_blank">
                        <GrYoutube fontSize={20} color="white" />
                      </a>
                    </span>
                  </div> */}



                  <div>
                    <a
                      href="https://www.facebook.com/BDBeautyGlamorous"
                      target="_blank"
                      className="px-2 cursor"
                    >
                      {/* <FaFacebook className=" headerFacebook" /> */}
                      <img src={facebook} alt="facebook" style={{ width: "25px" }} />
                    </a>
                    <a
                      href="https://www.instagram.com/bdbeautyglamorous_/"
                      target="_blank"
                      className="px-2 cursor"
                    >
                      {/* <FaInstagram className=" headerInstagram" /> */}
                      <img src={instagram} alt="instagram" style={{ width: "25px" }} />
                    </a>
                    <a
                      href="https://www.youtube.com/@bdbeautyglamorous7388"
                      target="_blank"
                      className="px-2 cursor"
                    >
                      {/* <FaYoutube className=" headerYoutube" /> */}
                      <img src={youtube} alt="youtube" style={{ width: "25px" }} />
                    </a>
                    <a
                      href="https://www.tiktok.com/@bdbeautyglamorous"
                      target="_blank"
                      className="px-2 cursor"
                    >
                      {/* <FaTiktok className=" headerYoutube" /> */}
                      <img src={tiktok} alt="tiktok" style={{ width: "25px" }} />
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-md-3 col-12 mt-3" style={{ color: "white" }}>
                <div style={{ marginBottom: "25px" }}>
                  <h5 style={{ color: "white", fontSize: "17px" }}>
                    GET IN TOUCH
                  </h5>
                </div>
                <div className="col-12">
                  <div style={{ color: "white" }}>
                    <div className="d-flex">
                      <div className="pe-1">
                        <img
                          src={phone_android_whiteIcon}
                          width={15}
                          height={15}
                          alt=""
                        />
                      </div>
                      <div>Call us at</div>
                    </div>
                    <div className="info_content d-flex">
                      <div>
                        <a
                          href={`tel:${websiteInfo.contact_number}`}
                          className="mail_link text-decoration-none"
                        >
                          {websiteInfo.contact_number}
                        </a>
                      </div>
                    </div>
                    <div
                      style={{
                        color: "gray",
                        fontSize: "12px",
                      }}
                    >
                      Monday to Friday : 9 AM to 7 PM
                    </div>
                  </div>
                  <div style={{ color: "white" }}>
                    Support
                    <p className="info_content">
                      <a
                        className="mail_link text-decoration-none"
                        href={`mailto:${websiteInfo.email}`}
                      >
                        {websiteInfo.email}
                      </a>
                    </p>
                  </div>
                  <div style={{ color: "white" }}>
                    Careers
                    <p className="info_content">
                      <a
                        className="mail_link text-decoration-none"
                        href={`mailto:${websiteInfo.email}`}
                      >
                        {websiteInfo.email}
                      </a>
                    </p>
                  </div>
                  <div style={{ color: "white" }}>
                    PR &amp; Media
                    <p className="info_content">
                      <a
                        className="mail_link text-decoration-none"
                        href={`mailto:${websiteInfo.email}`}
                      >
                        {websiteInfo.email}
                      </a>
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-3 col-12 mt-3" style={{ color: "white" }}>
                <div style={{ marginBottom: "25px" }}>
                  <h5 style={{ color: "white", fontSize: "17px" }}>
                    INFORMATION
                  </h5>
                </div>
                <div className="col-12">
                  <div className="info_content">
                    <a className="pages_link" href={`mailto:${common.email}`}>
                      Contact Us
                    </a>
                  </div>
                  <div className="info_content">
                    <Link to="/terms">Terms &amp; Conditions</Link>
                  </div>
                  <div className="info_content">
                    <Link to="/returns">Returns</Link>
                  </div>
                  <div className="info_content">
                    <Link to="/faqs">FAQs</Link>
                  </div>
                  <div className="info_content">
                    <Link to="/about-us">About Us</Link>
                  </div>
                </div>
              </div>
              <div className="col-md-4 col-12 mt-3" style={{ color: "white" }}>
                <div className="col-12 ">
                  <div style={{ marginBottom: "25px" }}>
                    <h5
                      style={{
                        color: "white",
                        fontSize: "17px",
                      }}
                    >
                      SUBSCRIBE TO OUR NEWSLETTER
                    </h5>
                  </div>
                  <p className="newsletter_content d-flex">
                    <i className="fa fa-envelope" />
                    <input
                      type="email"
                      placeholder="Your email address"
                      style={{ color: "white" }}
                      className="col-6"
                      onChange={(e) => setSubscribeEmail(e.target.value)}
                    />
                    <br />
                    <button className="mx-1 rounded" onClick={handleSubscribe}>
                      SUBSCRIBE
                    </button>
                  </p>
                </div>
                {/* <div className="col-12">
                  <div style={{ marginBottom: "25px" }}>
                    <h5
                      style={{
                        color: "white",
                        fontSize: "17px",
                      }}
                    >
                      GET THE NEW {websiteInfo.name} APP TODAY.
                    </h5>
                  </div>
                  <div className="col-12">
                    <div className="" style={{ color: "#999999" }}>
                      Tap into a better shopping experience.
                    </div>
                    <div className="row">
                      <div className="col-6">
                        <a href="#" target="_blank">
                          <img
                            src={playstoreIcon}
                            alt="playstore logo"
                            style={{ marginRight: "3px" }}
                            height="30px"
                          />
                        </a>
                      </div>
                      <div className="col-6">
                        <a href="#" target="_blank">
                          <img
                            src={appleStoreIcon}
                            alt="apple store logo"
                            height="30px"
                          />
                        </a>
                      </div>
                    </div>
                  </div>
                </div> */}
              </div>
            </div>
          </div>
          <div className="d-md-flex" style={{ borderTop: "1px solid #6D8B99" }}>
            {width <= 768 ? (
              <section
                className="mt-5"
                style={{
                  textAlign: "center",
                  margin: "auto",
                  // paddingBottom: "80px",
                  paddingBottom: "20px",
                  paddingTop: "20px",
                }}
              >
                <div
                  style={{
                    color: "white",
                    fontFamily: "'Poppins', sans-serif",
                  }}
                >
                  Copyright © {currentYear} {websiteInfo.name}. All rights
                  reserved.
                </div>
              </section>
            ) : (
              <section
                className="py-4 mt-5"
                style={{
                  borderTop: "1px solid #6D8B99",
                  textAlign: "center",
                  margin: "auto",
                }}
              >
                <div
                  style={{
                    color: "white",
                    fontFamily: "'Poppins', sans-serif",
                  }}
                >
                  Copyright © {currentYear} {websiteInfo.name}. All rights
                  reserved.
                </div>
              </section>
            )}
            <p
              className="footer-developed"
              style={{
                color: "white",
                fontFamily: "'Poppins', sans-serif",
              }}
            >
              Developed by STITBD
            </p>
          </div>
        </footer>
      </div>
    </footer>
  );
}
