import { Link } from "react-router-dom";
import useWidth from "../hooks/useWidth";
import shades from "../images/shades.png";
import { LazyLoadImage } from "react-lazy-load-image-component";

export default function SectionCard({ data }) {
  const width = useWidth();
  return (
    <div
      className="col-6 col-md-4 col-xl-3 product-card"
      style={width <= 768 ? { width: 175, padding: 0, margin: "0 8px" } : {}}
    >
      <div
        className="shadow-sm mx-lg-2 position-relative bg-white"
        style={width <= 768 ? { border: "1px solid rgba(0,0,0,.125)" } : {}}
      >
        <div className="ps-1 " style={{ position: "absolute", left: "-4px" }}>
          <Link to={`/product/${data.slug}`} style={{ color: "black" }}></Link>
        </div>
        <div
          className="position-absolute start-100 translate-middle"
          style={{ top: "15px", right: "-5rem" }}
        >
          <Link to={`/product/${data.slug}`} style={{ color: "black" }}>
            <div>
              <div className="d-flex">
                <span
                  className="pt-1"
                  style={{
                    fontSize: "12px",
                    fontWeight: "bold",
                  }}
                >
                  {data.item}
                </span>
                <span className="px-2">
                  {data.total_colors > 0 && (
                    <>
                      {data.total_colors}
                      &nbsp;
                      <img
                        src={shades}
                        width="15px"
                        height="15px"
                        alt="shades"
                        title="shades"
                      />
                    </>
                  )}
                </span>
              </div>
            </div>
          </Link>
        </div>
        <div className="position-relative">
          <div
            className={`${data?.stock ? "" : "overlay"} mt-2 text-center`}
            style={{ height: width >= 768 ? 250 : 150 }}
          >
            <Link to={`/product/${data.slug}`}>
              <span
                className="lazy-load-image-background  lazy-load-image-loaded"
                style={{
                  display: "inline-block",
                }}
              >
                <img
                  alt="Product"
                  src={data.image_path}
                  // title="Matte As Hell Crayon Lipstick"
                  style={{
                    objectFit: "contain",
                    width: "100%",
                    height: width >= 768 ? 250 : 150,
                  }}
                />

              </span>
            </Link>
            {
              data?.stock <= 0 && (
                <div className="overlay-text" >
                  <p>Out of Stock</p>
                </div>
              )
            }
          </div>
          {/* <div>
            {
              data?.stock <= 0 && (
                <div className="position-absolute top-0 left-0" style={{ position: "absolute", left: "5px !important", top: "15px !important", color: "red", border: "1px solid red", padding: "3px", display: "flex", justifyContent: "center", alignContent: "center", height: "35px", borderRadius: "25px", marginTop: "18px", fontWeight: 700 }}>
                  <p>Out of Stock</p>
                </div>
              )
            }

          </div> */}
        </div>
        <div style={{ height: "80px" }}>
          <Link to={`/product/${data.slug}`} style={{ color: "black" }}>
            <div className="p-1 d-flex justify-content-center">
              <div className="homeProductTitle mt-2">
                <h6 style={width <= 768 ? { fontSize: 11 } : {}}>
                  <span>{data.name}</span>
                </h6>
              </div>
            </div>
            <div
              className="homePricingDetails"
              style={width <= 768 ? { fontSize: 11 } : {}}
            >
              {/* {data.discount && (
                <div className="strikeThrough">
                  <span>Tk </span>
                  <span>{data.price}</span>
                </div>
              )}
              <div className="px-1 px-md-2 ">
                <span>Tk </span>
                <span>
                  {data.discount ? data.discount.price : data.sale_rate}
                </span>
              </div>
              {data.discount && (
                <div>
                  <span className="discount-off-percentage">
                    ({data.discount.percentage} Off)
                  </span>
                </div>
              )} */}

              {
                data?.discount_amount ? (
                  <div className="px-1 px-md-2">
                    <span>Tk </span>
                    <span className="strikeThrough">
                      {data?.sale_rate}
                    </span>
                    <span className="ms-2">Tk </span>
                    <span>{data.sale_rate - data?.discount_amount}</span>
                  </div>
                ) : (
                  <div className="px-1 px-md-2 ">
                    <span>Tk </span>
                    <span>
                      {data.sale_rate}
                    </span>
                  </div>
                )
              }
            </div>
          </Link>
        </div>
        <div style={{ height: width >= 768 ? 40 : 30 }}>
          <div className="mt-2">
            <div className="row" style={{ height: width >= 768 ? 40 : 30 }}>
              <div className="col-12">
                <Link
                  to={`/product/${data.slug}`}
                  style={{ color: "black !important" }}
                >
                  <button
                    className="btn btn-dark collectionButton"
                    style={{
                      outline: "none",
                      height: "100%",
                      width: "calc(100% + 1px)",
                      fontSize: "33px !important",
                      color: "black !important",
                    }}
                  >
                    See Details
                    <div className=" mx-4" />
                  </button>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
