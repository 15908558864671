import axios from "axios";


export const setCsrfToken = () => {
	axios.create({
		baseURL: "https://web.bdbeautyglamorous.com/",
		// baseURL: "http://localhost/bdbeauty_ecommerce/",
		headers : {
			'X-Requested-With': 'XMLHttpRequest'
		},
		withCredentials : true
	})
	.get('/sanctum/csrf-cookie')
	.then(response => {
		// console.log('CSRF Token Set',response.headers)
	})
	.catch((err) => {
		// console.log('Hear are error', err)
	});
}

const axiosConfig = axios.create({
	baseURL: "https://web.bdbeautyglamorous.com/web-api/",
	// baseURL: "http://localhost/bdbeauty_ecommerce/web-api/",
});

axiosConfig.defaults.headers.common["Access-Control-Allow-Origin"] = "*";
axiosConfig.defaults.headers.common["Access-Control-Allow-Methods"] = "GET,PUT,POST,DELETE,PATCH,OPTIONS";

export default axiosConfig;
