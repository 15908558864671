import { useEffect, useState } from "react";
import axios from "../lib/axiosConfig";

const useSectionList = () => {
	const [loading, setLoading] = useState(true);
	const [error, setError] = useState("");
	const [sections, setSections] = useState([]);

	useEffect(() => {
		async function fetchSections() {
			try {
				await axios
					.get("all-sections")
					.then((response) => {
						setSections(response.data.data);
						setLoading(false);
						setError(false);
						// localStorage.setItem("sections", JSON.stringify(response.data.data));
					})
					.catch((err) => {
						setSections([]);
						setLoading(false);
						setError(err);
						// localStorage.setItem("sections", JSON.stringify([]));
					});
			} catch (error) {
				// localStorage.setItem("sections", JSON.stringify([]));
				setLoading(false);
				setError("Connection Error");
			}
		}

		// if(localStorage.getItem('sections') && JSON.parse(localStorage.getItem('sections')).length){
		// 	setSections(JSON.parse(localStorage.getItem('sections')));
		// 	setLoading(false);
		// 	setError(false);
		// }
		// else{
		// 	fetchSections();
		// }
		fetchSections();

		return () => {
			setSections([]);
			setError("");
			setLoading(true);
		};
	}, []);

	return {
		loading,
		error,
		sections,
	};
};

export default useSectionList;
