import checkCircle from '../images/check_circle_white.svg'
import copyIcon from '../images/copyIcon.svg'
import blackRibbon from '../images/blackRibbon.png'
import { Link } from 'react-router-dom'
import useWidth from '../hooks/useWidth'

export default function Offerblog({ offer, copy, setCopy }) {
	const width = useWidth()

	return (
		<div className="position-relative">
			<Link to={`/offer/${offer.slug}`}>
				<img
					src={offer.image_path}
					width="100%"
					height={width >= 768 ? '320rem' : 'auto'}
					className="rounded-3"
					alt=''
				/>
			</Link>
			<div className="mainCodeOffer">
				<div className="position-relative cursor">
					<div
						className="overRibbonOffer offer-code-bg justify-content-end position-absolute ps-4 py-1"
						style={{
							backgroundImage: `url(${blackRibbon})`,
						}}
						onClick={() => {
							navigator.clipboard.writeText(offer.name)
							setCopy(offer.name)
							setTimeout(setCopy, 5000, '')
						}}
					>
						<div>{copy === offer.name ? 'Copied' : offer.name}</div>
						<div className="px-1">
							<img
								src={copy === offer.name ? checkCircle : copyIcon}
								height="14px"
								width="14px"
								alt=''
							/>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}
