import image from "../images/image1x1.jpg";
import image1 from "../images/image1x1-1.jpg";
import Offerblog from "../components/OfferBlog";
import { useState, useEffect } from "react";
import Footer from "../components/Footer";
import MBFooter from "../components/MBFooter";
import useWidth from "../hooks/useWidth";
import BackNavbar from "../components/BackNavbar";
import Navbar from "../components/Navbar";
import axios from "../lib/axiosConfig";
import {
  isLoggedInCheck,
  callToast,
  setValueLocalStorage,
  getValueLocalStorage,
} from "../lib/functions";
import { useWebsite } from "../context/WebsiteContext";
import MBNavbar from "../components/MBNavbar";
import { Link, useLocation, useNavigate } from "react-router-dom";


import {
  ErrorMessage,
  FastField,
  Field,
  FieldArray,
  Form,
  Formik,
} from "formik";
import * as Yup from "yup";
import ReactPixel from "react-facebook-pixel";

// Initialize ReactPixel with your Pixel ID
ReactPixel.init("540109202031009");

const initialValues = {
  email: "",
  password: "",
};

export default function ForgotPassword() {
  const {
    websiteInfo,
    setUserInfo,
    setTotalCartQuantity,
    setTotalCartAmount,
    cartDispatch,
    setWishlist,
    setWishlistCount,
  } = useWebsite();
  const width = useWidth();
  let navigate = useNavigate();
  const [emailCustomError, setEmailCustomError] = useState("");

  useEffect(() => {
    localStorage.setItem("forgotPass", true);
  }, [])

  const validationSchema = Yup.object({
    email: Yup.string()
      .email("Invalid email format")
      .required("Required Email Address!"),
  });
  useEffect(() => {
    ReactPixel.pageView(); // For tracking page view

  }, []);

  const onSubmit = async (values, onSubmitProps) => {
    await axios({
      method: "post",
      url: "forgot-password",
      data: values,
    })
      .then((response) => {
        callToast("success", response?.data?.message);
        ReactPixel.track('Lead', { content_name: 'Forgot Password' });

        return navigate("/login");
      })
      .catch((err) => {
        callToast("error", "Invalid Email");

        setEmailCustomError("");

        if (err.response && err.response.data.errors) {
          err.response.data.errors.forEach((item, index) => {
            if (item.code === "email") {
              setEmailCustomError(item.message);
            }
          });
        }
      });
  };

  return (
    <>
      {width >= 768 ? <Navbar /> : <MBNavbar searchButton={false} />}

      <div className="mt-4 mx-3 bg-white" style={{ borderRadius: "20px" }}>
        <div className="row pt-2 container-fluid">
          <div
            className="offset-md-3 col-md-5 col-lg-5 my-2 bg-white shadow-lg py-5 px-5 mb-4 mx-auto"
            style={{ borderRadius: "20px" }}
          >
            <section id="section-basic-example">
              <img
                src={websiteInfo.photo_path}
                title="BD Beauty Glamorous"
                alt="BD Beauty Glamorous"
                style={{
                  // position: "absolute",
                  // marginTop: "-10px",
                  width: "150px",
                  height: "60px",
                }}
                className="d-flex justify-content-center text-center mx-auto"
              />
              <h2 className="mb-4 text-center">Forgot Password</h2>
              <section className="pb-4">
                <Formik
                  initialValues={initialValues}
                  validationSchema={validationSchema}
                  onSubmit={onSubmit}
                //   validateOnChange={false}
                //   validateOnBlur={false}
                >
                  {(formik) => {
                    return (
                      <Form>
                        <div className="mb-3">
                          <label htmlFor="email">Number or E-mail</label>
                          <Field
                            type="text"
                            id="email"
                            name="email"
                            placeholder="Number or E-mail"
                            className="form-control"
                          />
                          <ErrorMessage name="email">
                            {(msg) => <div className="error">{msg}</div>}
                          </ErrorMessage>
                          {emailCustomError !== "" && (
                            <div className="error">{emailCustomError}</div>
                          )}
                        </div>
                        <div className="d-grid">
                          <button type="submit" className="btn btn-primary">
                            Submit
                          </button>
                        </div>
                      </Form>
                    );
                  }}
                </Formik>
              </section>
            </section>
          </div>
        </div>
      </div>

      {width >= 768 ? <Footer /> : <MBFooter />}
    </>
  );
}
