import { useEffect, useState } from "react";
import axios from "../lib/axiosConfig";

const useVideoStreaming = () => {
	const [loading, setLoading] = useState(true);
	const [error, setError] = useState("");
	const [videoStreamings, setVideoStreamings] = useState([]);

	useEffect(() => {
		async function fetchVideoStreamings() {
			try {
				await axios
					.get("all-video-streamings")
					.then((response) => {
						setVideoStreamings(response.data.data);
						setLoading(false);
						setError(false);
						localStorage.setItem("videoStreamings", JSON.stringify(response.data.data));
					})
					.catch((err) => {
						setVideoStreamings([]);
						setLoading(false);
						setError(err);
						localStorage.setItem("videoStreamings", JSON.stringify([]));

					});
			}
			catch (error) {
				setVideoStreamings([]);
				localStorage.setItem("videoStreamings", JSON.stringify([]));
			}
		}

		// if(localStorage.getItem('videoStreamings') && JSON.parse(localStorage.getItem('videoStreamings')).length){
		// 	setVideoStreamings(JSON.parse(localStorage.getItem('videoStreamings')));
		// 	setLoading(false);
		// 	setError(false);
		// }
		// else{
			fetchVideoStreamings();
		// }

		return () => {
			setVideoStreamings([]);
			setError("");
			setLoading(true);
		};
	}, []);

	return {
		loading,
		error,
		videoStreamings,
	};
};

export default useVideoStreaming;
