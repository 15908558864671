import React, { createContext, useState, useEffect, useContext } from 'react';
import { toast } from 'react-toastify';

const CartContext = createContext();

export const useCart = () => {
    return useContext(CartContext);
};

export const CartProvider = ({ children }) => {
    const [carts, setCarts] = useState([]);

    useEffect(() => {
        // Load cart from localStorage on initial render
        const storedCart = localStorage.getItem('cartItem');

        if (storedCart) {
            try {
                const cartsFromLocalStorage = JSON.parse(storedCart);
                setCarts(cartsFromLocalStorage);
            } catch (error) {
                console.error("Error parsing cart items from localStorage:", error);
                // Initialize with an empty array if parsing fails
                setCarts([]);
            }
        }
    }, []);

    const handleAddToCart = (newItem) => {
        const updatedCart = [...carts];
        const existingProductIndex = updatedCart?.findIndex(item => item.product.id === newItem.product.id);

        if (existingProductIndex !== -1) {
            updatedCart[existingProductIndex].quantity += newItem.quantity;
        } else {
            updatedCart.push(newItem);
        }

        toast.success('Product added to cart');
        setCarts(updatedCart);
        localStorage.setItem('cartItem', JSON.stringify(updatedCart));
    };

    const handleUpdateCartQuantity = (product, type) => {
        const updatedCart = carts?.map((item) => {
            var quantity = type === "increment" ? 1 : -1;
            if (item?.product?.id === product?.id) {
                if (item?.quantity > 1 || type === "increment") {
                    toast.success("Product quantity updated successfully");
                    return { ...item, quantity: item.quantity + quantity };
                }
                else {
                    toast.error("Quantity can't be less than 1");
                }
            }
            return item;
        });

        setCarts(updatedCart);
        localStorage.setItem('cartItem', JSON.stringify(updatedCart));
    };

    const handleRemoveFromCart = (product) => {
        const updatedCart = carts.filter(item => item.product.id !== product.id);

        toast.success('Product removed from cart');
        setCarts(updatedCart);
        localStorage.setItem('cartItem', JSON.stringify(updatedCart));
    };


    return (
        <CartContext.Provider value={{ carts, handleAddToCart, handleUpdateCartQuantity, handleRemoveFromCart, setCarts }}>
            {children}
        </CartContext.Provider>
    );
};
