import { MdLocalMall, MdFavorite, MdClose } from "react-icons/md";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import logo from "../images/logo1x1.jpg";
import SearchPalette from "./SearchPalette";
import persionIcon from "../images/person.png";
import discountIcon from "../images/discountIcon.svg";
import { useWebsite } from "../context/WebsiteContext";
import { useNavigate, useSearchParams } from "react-router-dom";
import { isLoggedInCheck, callToast } from "../lib/functions";
import axios from "../lib/axiosConfig";
import useUser from "../hooks/useUser";
import { FaFacebook } from "react-icons/fa";
// import { FaInstagram } from "react-icons/fa6";
import { FaYoutube } from "react-icons/fa";
import { FaInstagram } from "react-icons/fa";
import { AiOutlineLogin } from "react-icons/ai";
import { CiLogin } from "react-icons/ci";
import { IoPerson } from "react-icons/io5";
import { CgLogOut } from "react-icons/cg";
import useHomePageContent from "../hooks/useHomePageContent";
import cart from '../images/Cart.png'
import wishlist from '../images/Heart.png'
import profile from '../images/Profile.png'
import { useCart } from "../context/CartContext";
import { useWishlist } from "../context/WishlistContext";

export default function NavSearchbar({ handler }) {
  let navigate = useNavigate();
  const { carts, handleAddToCart } = useCart();
  const { wishlists, handleAddToWishlist } = useWishlist();
  const { logoutUser } = useUser();
  const {
    websiteInfo,
    wishlistCount,
    isLoggedInState,
    setIsLoggedInState,
    totalCartQuantity,
    setTotalCartQuantity,
  } = useWebsite();

  const [searchPalette, setSearchPalette] = useState(false);
  const [search, setSearch] = useState("");
  const changeSearch = (e) => setSearch(e.currentTarget.value);

  const searchProduct = () => {
    navigate({ pathname: "/search", replace: true, search: `?q=${search}` });
  };

  const handleKeyUp = (e) => {
    if (e.which === 13) {
      searchProduct();
    }
  };


  const { loading: homePageContentLoading, homePageContents } =
    useHomePageContent();
  return (
    <div className="d-flex bg-white py-3">
      <div className="col-md-11 mx-auto">
        <div className="row justify-content-between">
          <div className="col-3 d-flex">
            <Link to="/" style={{ color: "black" }}>
              <div className="py-0">
                <img
                  src={websiteInfo.photo_path}
                  title="BD Beauty Glamorous"
                  alt="BD Beauty Glamorous"
                  style={{
                    position: "absolute",
                    marginTop: "-10px",
                    width: "150px",
                    height: "60px",
                  }}
                />
              </div>
            </Link>
          </div>
          <div className="col-4 d-flex justify-content-between">
            <div
              className="col-12 rounded-pill d-flex bg-white navbarDropdown"
              style={{ height: "40px" }}
              onMouseLeave={() => setSearchPalette(false)}
            >
              <input
                className="col-9 px-4 searchInput"
                placeholder="Search"
                value={search}
                onMouseEnter={() => setSearchPalette(true)}
                onChange={changeSearch}
                onKeyUp={(e) => handleKeyUp(e)}
              />
              <div
                className="col-1 d-flex justify-content-center pt-2 text-muted cursor-pointer"
                style={{ zIndex: 100, backgroundColor: "rgb(248, 249, 250)" }}
                onClick={() => setSearch("")}
              >
                {search !== "" && <MdClose fontSize={22} />}
              </div>
              <div
                className="col-3 bg-dark d-flex justify-content-center py-2 searchButton cursor-pointer"
                style={{
                  color: "white",
                  fontSize: "15px",
                }}
                onClick={() => searchProduct()}
              >
                Search
              </div>
              {searchPalette && <SearchPalette homePageContents={homePageContents} id={11} />}
            </div>
          </div>
          <div
            className="col-3 d-flex justify-content-center"
            style={{ fontSize: "15px" }}
          >
            <div className="d-flex justify-content-center">
              {/* <div className="py-2">
                <img src={persionIcon} className="" alt="" />
              </div> */}
              {isLoggedInState === true ? (
                <>
                  <div
                    className="px-1 py-2 text-decoration-underline cursor-pointer text-black d-flex align-items-center"
                    onClick={logoutUser}
                  >
                    {/* <CgLogOut
                      style={{ fontSize: "18px", marginRight: "4px" }}
                    /> */}
                    <img src={profile} className="me-1" alt="" style={{width: "25px"}} />
                    <span>Logout</span>
                  </div>
                  <Link
                    to="/profile"
                    className="px-1 py-2 text-decoration-underline cursor-pointer text-black d-flex align-items-center"
                  >
                    {/* <IoPerson
                      style={{ fontSize: "18px", marginRight: "4px" }}
                    /> */}
                    <img src={profile} className="me-1" alt="" style={{width: "25px"}} />
                    <span>Profile</span>
                  </Link>
                </>
              ) : (
                <>
                  <Link
                    to="/login"
                    className="px-1 py-2 text-decoration-underline cursor-pointer text-black d-flex align-items-center"
                  >
                    {/* <AiOutlineLogin
                      style={{ fontSize: "18px", marginRight: "4px" }}
                    /> */}
                    <img src={profile} className="me-1" alt="" style={{width: "25px"}} />
                    <span>Login</span>
                  </Link>
                  <Link
                    to="/registration"
                    className="px-1 py-2 text-decoration-underline cursor-pointer text-black d-flex align-items-center"
                  >
                    {/* <CiLogin style={{ fontSize: "18px", marginRight: "4px" }} /> */}
                    <img src={profile} className="me-1" alt="" style={{width: "25px"}} />
                    <span>Sign up</span>
                  </Link>
                </>
              )}
            </div>
          </div>
          <div className="col-1 d-flex justify-content-start  py-1">
            <div className="px-2 position-relative">
              <Link to="/wishlist" className="text-decoration-none color-black">
                <span data-bs-toggle="tooltip" data-bs-placement="top" title="Wishlist">
                  {/* <MdFavorite color="rgb(39 39 183)" fontSize="25px" /> */}
                  <img src={wishlist} alt="" style={{width: "35px"}} />
                </span>

                {
                  wishlists?.length > 0 ? (
                    <span className="position-absolute text-center productCartCount collectionOfferPosition">
                      <span className="px-1">{wishlists?.length}</span>
                    </span>

                  ) : (
                    wishlistCount && wishlistCount > 0 ? (
                      <span className="position-absolute text-center productCartCount collectionOfferPosition">
                        <span className="px-1">{wishlistCount}</span>
                      </span>
                    ) : (
                      ""
                    )
                  )
                }


              </Link>
            </div>
            <div className="px-1">
              <Link to="/cart" className="text-decoration-none color-black">
                <div className="position-relative">
                  <span data-bs-toggle="tooltip" data-bs-placement="top" title="Cart List">
                    {/* <MdLocalMall color="rgb(39 39 183)" fontSize="25px" /> */}
                    <img src={cart} alt="" style={{width: "35px"}} />
                  </span>

                  {
                    carts?.length > 0 ? (
                      <span className="position-absolute text-center productCartCount collectionOfferPosition">
                        <span className="px-1">{carts?.length}</span>
                      </span>
                    ) : (
                      totalCartQuantity && totalCartQuantity > 0 ? (
                        <span className="position-absolute text-center productCartCount collectionOfferPosition">
                          <span className="px-1">{totalCartQuantity}</span>
                        </span>
                      ) : (
                        ""
                      )
                    )
                  }


                </div>
              </Link>
            </div>
            {/* <Link to="/offers">
              <div className="px-2 cursor">
                <img
                  src={discountIcon}
                  alt="discount"
                  width="18px"
                  height="18px"
                />
              </div>
            </Link> */}
          </div>
          {/* <div className="col-1 d-flex  py-1">
            <a
              href="https://www.facebook.com/BDBeautyGlamorous"
              target="_blank"
              className="px-2 cursor"
            >
              <FaFacebook className="text-black headerFacebook" />
            </a>
            <a
              href="https://www.instagram.com/"
              target="_blank"
              className="px-2 cursor"
            >
              <FaInstagram className="text-black headerInstagram" />
            </a>
            <a
              href="https://www.youtube.com/"
              target="_blank"
              className="px-2 cursor"
            >
              <FaYoutube className="text-black headerYoutube" />
            </a>
          </div> */}
        </div>
      </div>
    </div>
  );
}
