import ReactImageZoom from "react-image-zoom";
import { MdKeyboardArrowUp, MdKeyboardArrowDown } from "react-icons/md";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import useWidth from "../hooks/useWidth";
import { LazyLoadImage } from "react-lazy-load-image-component";

const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 768 },
    items: 1,
  },
  mobile: {
    breakpoint: { max: 800, min: 0 },
    items: 1,
  },
};

export default function ProductDetailsPreview({
  preview,
  product,
  changeImage,
  all_images,
  handleKeyboardArrowUpEvents,
  handleKeyboardArrowDownEvents,
}) {
  const width = useWidth();

  // return <h1>Hello</h1>

  return (
    <>
      {width <= 500 && (
        <div className="position-relative pb-4 mb-3">
          <Carousel
            responsive={responsive}
            infinite={true}
            autoPlay={true}
            renderDotsOutside={true}
            showDots={true}
          >
            {all_images?.map((image) => (
              <div className="text-center col-md-9" key={image.id}>
                <img
                  height="360px"
                  width="100%"
                  src={image.image_path}
                  alt=""
                />
              </div>
            ))}
          </Carousel>
        </div>
      )}
      {width >= 501 && width <= 767 && (
        <div className="position-relative pb-4 mb-3">
          <Carousel
            responsive={responsive}
            infinite={true}
            autoPlay={true}
            renderDotsOutside={true}
            showDots={true}
          >
            {all_images?.map((image) => (
              <div className="text-center col-md-9" key={image.id}>
                <img
                  height="500px"
                  width="100%"
                  src={image.image_path}
                  alt=""
                />
              </div>
            ))}
          </Carousel>
        </div>
      )}

      {width >= 1400 && preview && preview?.length > 0 && (
        <div className="col-10 col-md-10 col-lg-8 col-xl-10 d-flex">
          <div style={{ zIndex: 2, width: "100%" }}>
            <ReactImageZoom
              width={860}
              height={900}
              zoomWidth={400}
              img={preview}
              className=""
              style={{ objectFit: "cover", width: "100%", maxWidth: "100%" }}
            />
          </div>
        </div>
      )}

      {width >= 768 && width <= 989 && preview && preview.length > 0 && (
        <div className="col-8 col-md-8 col-lg-8 col-xl-8 d-flex">
          <div style={{ zIndex: 2, width: "100%" }}>
            <ReactImageZoom
              width={370}
              height={380}
              zoomWidth={400}
              img={preview}
              className=""
              style={{ objectFit: "cover", width: "100%", maxWidth: "100%" }}
            />
          </div>
        </div>
      )}

      {width >= 990 && width <= 1199 && preview && preview.length > 0 && (
        <div className="col-9 col-md-9 col-lg-8 col-xl-9 d-flex">
          <div style={{ zIndex: 2, width: "100%" }}>
            <ReactImageZoom
              width={475}
              height={482}
              zoomWidth={400}
              img={preview}
              className=""
              style={{ objectFit: "cover", width: "100%", maxWidth: "100%" }}
            />
          </div>
        </div>
      )}
      {width >= 1200 && width <= 1399 && preview && preview.length > 0 && (
        <div className="col-9 col-md-9 col-lg-8 col-xl-9 d-flex">
          <div style={{ zIndex: 2, width: "100%" }}>
            <ReactImageZoom
              width={670}
              height={620}
              zoomWidth={400}
              img={preview}
              className=""
              style={{ objectFit: "cover", width: "100%", maxWidth: "100%" }}
            />
          </div>
        </div>
      )}

      <div className="col-2 col-md-2 col-lg-2 product2DesktopSwatchWrapper d-none d-md-block">
        <div className="position-relative">
          <div
            className="position-absolute bg-dark text-center text-white sdlkje"
            onClick={(e) => handleKeyboardArrowUpEvents(e)}
          >
            <MdKeyboardArrowUp fontSize={30} />
          </div>
          <div
            className="position-absolute bg-dark text-center text-white sdlfkjel"
            onClick={(e) => handleKeyboardArrowDownEvents(e)}
          >
            <MdKeyboardArrowDown fontSize={30} />
          </div>

          {all_images?.map((image, index) => (
            <div
              className="d-flex justify-content-center cursor"
              key={index}
              style={index === 0 ? { paddingTop: 36 } : {}}
            >
              <img
                className="my-2 prodProductDiv"
                src={image.image_path}
                onMouseEnter={changeImage}
                style={{ height: 115 }}
                alt="Product Preview"
              />
            </div>
          ))}
        </div>
      </div>
    </>
  );
}
