import { Link } from "react-router-dom";
import { MdTrendingUp, MdWhatshot } from "react-icons/md";
import React from "react";
import withSearchPalette from "../HOCs/withSearchPalette";
import useHomePageContent from "../hooks/useHomePageContent";

function SearchPalette({ topSearch, hotPicks }) {
  const { loading: homePageContentLoading, homePageContents } =
    useHomePageContent();

  const allProducts = homePageContents.find(
    (content) =>
      content?.section?.name.toString().toLowerCase().replace(/\s/g, "") ===
      "allproducts"
  );
  const newArrival = homePageContents.find(
    (content) =>
      content?.section?.name.toString().toLowerCase().replace(/\s/g, "") ===
      "newarrival"
  );

  return (
    <div className="navbarDropdownContent col-4 search-palette">
      <div className="px-2 pt-5">
        {/* <div>
          <div className="d-flex">
            <div className="searchTags py-2 px-1">
              <MdTrendingUp fontSize={15} />
            </div>
            <div className="searchTags py-2">Frequently Searched</div>
          </div>
          <div className="d-flex flex-wrap">
            {topSearch?.map((data) => (
              <div className="px-1 mb-2" key={data.id}>
                <Link to={data.path}>
                  <div className="rounded-pill px-1 search-palette-value">
                    {data.value}
                  </div>
                </Link>
              </div>
            ))}
          </div>
        </div>
        <div>
          <div className="d-flex">
            <div className="searchTags py-2 px-1">
              <MdWhatshot fontSize={15} />
            </div>
            <div className="searchTags py-2">Hot Picks</div>
          </div>
          <div className="d-flex flex-wrap pb-2">
            {hotPicks?.map((hotPick) => (
              <div className="px-2" key={hotPick.id}>
                <Link to={hotPick.path}>
                  <div
                    style={{
                      height: "4rem",
                      background: "white",
                    }}
                  >
                    <img
                      src={hotPick.image}
                      height="100%"
                      className="px-1 py-1"
                      alt=""
                    />
                  </div>
                </Link>
              </div>
            ))}
          </div>
        </div> */}
        <div>
          <div>
            <div className="d-flex justify-content-between">
              <div className="d-flex">
                <div className="SearchNavbar_searchTags py-2 px-1">
                  <MdWhatshot fontSize={16} />
                </div>
                <div className="SearchNavbar_searchTags px-2 py-2">
                  All Products
                </div>
              </div>
              <div className="mt-2">
                <Link to="/products" className="border border-1 px-2 py-1">
                  See All
                </Link>
              </div>
            </div>
            <div className="d-flex flex-wrap">
              {allProducts?.section?.product_sections
                ?.slice(0, 12)
                ?.map((product) => (
                  <div className="px-2" key={product.id}>
                    <Link to={`/product/${product?.product?.slug}`}>
                      <div style={{ height: "3.5rem" }}>
                        <img
                          src={product?.product?.image_path}
                          height="100%"
                          style={{ borderRadius: "5px" }}
                        />
                      </div>
                    </Link>
                    <div
                      className="pt-1 d-inline-block text-truncate"
                      style={{
                        fontSize: "0.6rem",
                        textAlign: "center",
                        maxWidth: "50px",
                      }}
                    >
                      {product?.product?.name}
                    </div>
                  </div>
                ))}
            </div>
          </div>
          <div>
            <div className="d-flex justify-content-between">
              <div className="d-flex">
                <div className="SearchNavbar_searchTags py-2 px-1">
                  <MdTrendingUp fontSize={16} />
                </div>
                <div className="SearchNavbar_searchTags px-2 py-2">
                  New Arrival
                </div>
              </div>
              <div className="mt-2">
                <Link to="/products" className="border border-1 px-2 py-1">
                  See All
                </Link>
              </div>
            </div>

            <div className="d-flex flex-wrap">
              {newArrival?.section?.product_sections
                ?.slice(0, 6)
                ?.map((product) => (
                  <div className="px-2" key={product.id}>
                    <Link to={`/product/${product?.product?.slug}`}>
                      <div style={{ height: "3.5rem" }}>
                        <img
                          src={product?.product?.image_path}
                          height="100%"
                          style={{ borderRadius: "5px" }}
                        />
                      </div>
                    </Link>
                    <div
                      className="pt-1 d-inline-block text-truncate"
                      style={{
                        fontSize: "0.6rem",
                        textAlign: "center",
                        maxWidth: "50px",
                      }}
                    >
                      {product?.product?.name}
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default React.memo(withSearchPalette(SearchPalette));
