import { useEffect, useState } from "react";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import { MdFavoriteBorder, MdLocalMall, MdShoppingCart } from "react-icons/md";
import { Link, useParams, useNavigate } from "react-router-dom";
import StarRatings from "react-star-ratings";
import AddToCartButton from "../components/AddToCartButton";
import BestSeller from "../components/BestSeller";
import Footer from "../components/Footer";
import MBSection from "../components/MBSection";
import LoadingSpinner from "../components/LoadingSpinner";
import MBNavbar from "../components/MBNavbar";
import Navbar from "../components/Navbar";
import ProductPreview from "../components/ProductPreview";
import SocialShare from "../components/SocialShare";
import useWidth from "../hooks/useWidth";
import bereadcrumbIcon from "../images/breadcrumb_home.svg";
import productbanner from "../images/productbanner.svg";
import axios from "../lib/axiosConfig";
import { pad, isEmpty, numberWithSpaces, callToast } from "../lib/functions";
import Section from "../components/Section";
// import { useWebsite } from "../context/WebsiteContext";
import { Helmet } from "react-helmet";
import useAddProduct from "../hooks/useAddProduct";
import ReactPixel from "react-facebook-pixel";
import MessengerCustomerChat from "react-messenger-customer-chat";
import MBFooter from "../components/MBFooter";

// Initialize ReactPixel with your Pixel ID
ReactPixel.init("540109202031009");

const productDatam = [
  {
    id: 348893,
    color: "rgb(186, 123, 131)",
    name: "Roaring Rose",
    images: [
      {
        id: 239487,
        url: "https://cdn.shopify.com/s/files/1/0906/2558/products/sugar-cosmetics-swipe-right-hand-rub-01-roaring-rose-15543492280403.jpg?v=1619120146",
      },
      {
        id: 29343098,
        url: "https://cdn.shopify.com/s/files/1/0906/2558/products/sugar-cosmetics-swipe-right-hand-rub-01-roaring-rose-15543503093843.jpg?v=1619120148",
      },
      {
        id: 8094584,
        url: "https://cdn.shopify.com/s/files/1/0906/2558/products/sugar-cosmetics-swipe-right-hand-rub-01-roaring-rose-15543065149523.jpg?v=1619120150",
      },
    ],
  },
  {
    id: 93485,
    color: "rgb(152, 45, 39)",
    name: "Bold Berry",
    images: [
      {
        id: 9324803298,
        url: "https://cdn.shopify.com/s/files/1/0906/2558/products/sugar-cosmetics-swipe-right-hand-rub-02-bold-berry-15543508336723.jpg?v=1619120162",
      },
      {
        id: 23489833,
        url: "https://cdn.shopify.com/s/files/1/0906/2558/products/sugar-cosmetics-swipe-right-hand-rub-02-bold-berry-15543059546195.jpg?v=1619120165",
      },
      {
        id: 29384903,
        url: "https://cdn.shopify.com/s/files/1/0906/2558/products/sugar-cosmetics-swipe-right-hand-rub-02-bold-berry-15543507943507.jpg?v=1619120168",
      },
    ],
  },
  {
    id: 932409,
    color: "rgb(116, 76, 128)",
    name: "Lush Lavender",
    images: [
      {
        id: 239482039,
        url: "https://cdn.shopify.com/s/files/1/0906/2558/products/sugar-cosmetics-swipe-right-hand-rub-03-lush-lavender-15543059611731.jpg?v=1619120181",
      },
      {
        id: 87329,
        url: "https://cdn.shopify.com/s/files/1/0906/2558/products/sugar-cosmetics-swipe-right-hand-rub-03-lush-lavender-15543513415763.jpg?v=1619120179",
      },

      {
        id: 349839,
        url: "https://cdn.shopify.com/s/files/1/0906/2558/products/sugar-cosmetics-swipe-right-hand-rub-03-lush-lavender-15543512498259.jpg?v=1619120183",
      },
    ],
  },
  {
    id: 93985,
    color: "rgb(177, 174, 170)",
    name: "Very Vanilla",
    images: [
      {
        id: 230948,
        url: "https://cdn.shopify.com/s/files/1/0906/2558/products/sugar-cosmetics-swipe-right-hand-rub-04-very-vanilla-15543516299347.jpg?v=1619120193",
      },
      {
        id: 309823098,
        url: "https://cdn.shopify.com/s/files/1/0906/2558/products/sugar-cosmetics-swipe-right-hand-rub-04-very-vanilla-15543516495955.jpg?v=1619120197",
      },
      {
        id: 8709873,
        url: "https://cdn.shopify.com/s/files/1/0906/2558/products/sugar-cosmetics-swipe-right-hand-rub-04-very-vanilla-15543059972179.jpg?v=1619120195",
      },
    ],
  },
];

const recentlyViewd = [
  {
    id: 97394384,
    path: "/product", // products details apge
    item: 8, // Available item in our server.
    image:
      "https://cdn.shopify.com/s/files/1/0906/2558/products/sugar-cosmetics-swipe-right-hand-rub-01-roaring-rose-15541873836115.jpg?v=1619125116",
    title: `Matte As Hell Crayon Lipstick`,
    price: 799, // prodicut price
    discount: {
      price: 500, // price after discount
      percentage: "20%",
    },
  },
  {
    id: 23943849,
    path: "/product",
    item: 6,
    image:
      "https://cdn.shopify.com/s/files/1/0906/2558/products/01.jpg?v=1639496513",
    title: `Kohl Of Honour Intense Kajal`,
    price: 239,
  },
  {
    id: 82938409328,
    path: "/product",
    item: 6,
    image:
      "https://cdn.shopify.com/s/files/1/0906/2558/products/sugar-cosmetics-wingman-waterproof-microliner-01-i-ll-be-black-black-28163915841619.jpg?v=1619117351",
    title: `Limited Edition Makeup Trio Set`,
    price: 699,
  },
  {
    id: 4099834,
    path: "/product",
    item: 9,
    image:
      "https://cdn.shopify.com/s/files/1/0906/2558/products/sugar-cosmetics-smudge-me-not-minis-set-pink-14629810667603.jpg?v=1620652894",
    title: `Tipsy Lips Moisturizing Balm`,
    price: 199,
  },
];

export default function Product() {
  const width = useWidth();
  const navigate = useNavigate();
  const { handleProductIntoCart, handleProductIntoWishlist } = useAddProduct();

  const [product, setProduct] = useState({});
  const [similarProducts, setSimilarProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const [imagePreview, setImagePreview] = useState("");
  const [current, setCurrent] = useState(0);
  const [allImage, setAllImage] = useState([]);
  const [showPreviewImages, setShowPreviewImages] = useState([]);
  const [rating, setRating] = useState(3.5);
  const [selectedColor, setSelectedColor] = useState(null);

  useEffect(() => {
    ReactPixel.pageView(); // For tracking page view
  }, []);

  let { productSlug } = useParams();

  useEffect(() => {
    async function fetchProduct() {
      try {
        await axios({
          method: "post",
          url: "product",
          data: {
            slug: productSlug,
          },
        })
          .then((response) => {
            let productData = response.data.data.product;

            setProduct(productData);
            setSimilarProducts(response.data.data.similar_products);
            setImagePreview(productData.image_path);

            setAllImage(productData.all_images);
            // console.log(productData.all_images.length);+
            if (
              productData.product_skus &&
              productData.product_skus.length > 0
            ) {
              setSelectedColor(productData.product_skus[0]);
            }

            setLoading(false);
            setError(false);
          })
          .catch((err) => {
            setProduct({});
            setSimilarProducts([]);
            setLoading(false);
            setError(err);
          });
      } catch (error) {
        setProduct({});
        setSimilarProducts([]);
        setAllImage([]);
        setLoading(false);
        setError(error);
      }
    }

    if (productSlug) {
      fetchProduct();
    } else {
      navigate("/");
    }
  }, [productSlug]);

  useEffect(() => {
    setShowPreviewImages(allImage.slice(0, 3));
  }, [allImage]);

  const changeImage = (e) => {
    setImagePreview(e.currentTarget.src);
  };

  const handleKeyboardArrowUpEvents = (e) => {
    if (current > 0) {
      setShowPreviewImages(allImage.slice(current - 3, current));
      setCurrent((previousState) => previousState - 3);
    } else {
      setShowPreviewImages(allImage.slice(0, 3));
      setCurrent(0);
    }
  };

  const handleKeyboardArrowDownEvents = (e) => {
    if (allImage.length > current + 3) {
      setShowPreviewImages(allImage.slice(current + 3, current + 3 + 3));
      setCurrent((previousState) => previousState + 3);
    }
  };

  const handleSelectedColor = (e) => {
    if (product && product.product_skus && product.product_skus.length > 0) {
      const showSkuId = e.currentTarget.id;
      product.product_skus.forEach((sku) => {
        if (sku.id == showSkuId) {
          setImagePreview(sku.image_path);
          setSelectedColor(sku);
        }
      });
    }
  };

  // const seletedProduct = productDatam.filter((data, index) => data.color === 1)[0]
  const seletedProduct = productDatam.filter(
    (data, index) => data.color === selectedColor
  )[0];

  return (
    <>
      {width >= 768 ? (
        <Navbar />
      ) : (
        <MBNavbar searchButton={false} title="NavBar" />
      )}
      <div className="container pb-5 pb-md-0">
        {!loading && product === 0 && <div> No Data found</div>}

        {loading && !error && (
          <div>
            <LoadingSpinner />
          </div>
        )}

        {loading && error && <div> There is an error</div>}

        {!isEmpty(product) && (
          <>
            <Helmet>
              <title>{product.name}</title>
              <meta name="description" content={product.meta_description} />
              <meta name="keywords" content={product.meta_keywords} />
              <meta name="author" content={product.meta_author} />
            </Helmet>
            <div className="d-flex my-2">
              <div>
                <Link to="/">
                  <img src={bereadcrumbIcon} alt="Preview" />
                </Link>
              </div>
              <div className="d-flex">
                <div className="px-2 text-muted">/</div>
                <div className="text-muted">
                  <Link to={`/product/${product.slug}`}>
                    <span className="text-muted">{product.name}</span>
                  </Link>
                </div>
              </div>
            </div>
            <div className="pt-3 shadow-lg bg-white row">
              <div className="container-fluid px-4">
                <div className="row ">
                  <div className="col-lg-7">
                    <div className="row">
                      <ProductPreview
                        preview={imagePreview}
                        product={productDatam}
                        changeImage={changeImage}
                        all_images={showPreviewImages}
                        handleKeyboardArrowUpEvents={
                          handleKeyboardArrowUpEvents
                        }
                        handleKeyboardArrowDownEvents={
                          handleKeyboardArrowDownEvents
                        }
                      />
                    </div>
                  </div>

                  <div className="col-12 col-lg-5 d-flex mt-0">
                    <div>
                      <div className="mb-1">
                        <span
                          className="cursor-pointer"
                          style={{ fontSize: "24px" }}
                        >
                          {product.name}
                        </span>
                      </div>
                      {/* <div className="d-flex mb-2">
                        <div className="mt-1">
                          <StarRatings
                            rating={rating}
                            starRatedColor="rgb(255, 215, 0)"
                            name="rating"
                            starDimension="22px"
                            starSpacing="1px"
                            changeRating={setRating}
                            starSelectingHoverColor="rgb(255, 215, 0)"
                          />
                        </div>
                        <div className="py-2 px-2 cursor">
                          <span className="text-muted px-2">4.8 (73)</span>
                        </div>
                        <div />
                      </div> */}
                      <div className="d-flex mb-2">
                        {
                          product?.discount_amount ? (
                            <div className="px-1 px-md-2" style={{ fontSize: "18px" }}>
                              <span>Tk </span>
                              <span className="strikeThrough text-muted">
                                {numberWithSpaces(product?.sale_rate)}
                              </span>
                              <span className="ms-2">Tk </span>
                              <span>{numberWithSpaces(product.sale_rate - product?.discount_amount)}</span>
                            </div>
                          ) : (
                            <div className="px-1 px-md-2 ">
                              <span>Tk </span>
                              <span>
                                {product.sale_rate}
                              </span>
                            </div>
                          )
                        }
                        {/* <div>
                          <span
                            className="text-muted mx-2"
                            style={{ fontSize: "18px" }}
                          >
                            <span> Tk </span>{" "}
                            {numberWithSpaces(product.sale_rate)}
                          </span>
                        </div> */}
                        <div
                          className="px-2 text-muted"
                          style={{ fontSize: "18px" }}
                        />
                      </div>
                      <div
                        className="py-1"
                        style={{
                          display: "flex",
                          flexWrap: "wrap",
                          flexBasis: "100%",
                        }}
                      >
                        {product.product_skus?.map((sku) => (
                          <div className="mx-3" key={sku.id}>
                            <div className="row cursor-pointer">
                              <div
                                className={`product-color-container ${selectedColor && sku.id === selectedColor.id
                                  ? "active"
                                  : ""
                                  }`}
                                id={sku.id}
                                onClick={handleSelectedColor}
                              >
                                <div
                                  className="product-color-box"
                                  style={{
                                    backgroundColor: sku.color.code,
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>

                      {selectedColor && (
                        <div style={{ display: "flex" }}>
                          <div
                            className="my-1 current-selected-product-preview active"
                            style={{
                              backgroundColor: selectedColor.color.code,
                            }}
                          />
                          <div className=" mx-1" style={{ fontSize: "14px" }}>
                            {selectedColor.color.name}
                          </div>
                        </div>
                      )}

                      <div className="mb-3 mt-1 d-flex" style={{ width: "110%" }}>
                        <button
                          type="button"
                          className="btn btn-dark btn-block p-1 collectionButton"
                          style={{ width: "150px", margin: 0 }}
                          disabled={!product?.stock_product || product?.stock_product?.quantity < 1}

                        >
                          <div
                            className="d-flex justify-content-center align-items-center"
                            onClick={(e) =>
                              handleProductIntoCart(product, selectedColor)
                            }
                          >
                            <MdShoppingCart fontSize={16} />
                            <span>{(!product?.stock_product || product?.stock_product?.quantity < 1) ? "Out of Stock" : "Add to Cart"} </span>
                          </div>
                        </button>
                        <button
                          type="button"
                          className="btn btn-dark btn-block p-1 collectionButton ms-3"
                          style={{ width: "150px", margin: 0 }}
                          disabled={!product?.stock_product || product?.stock_product?.quantity < 1}
                        >
                          <div
                            className="d-flex justify-content-center align-items-center"
                            onClick={(e) =>
                              handleProductIntoWishlist(product, selectedColor)
                            }
                          >
                            <MdFavoriteBorder fontSize={16} />
                            <span>{(!product?.stock_product || product?.stock_product?.quantity < 1) ? "Out of Stock" : "Save to Wishlist"} </span>
                          </div>
                        </button>
                      </div>
                      {/* <div
                        className="d-flex mb-3"
                        style={{ cursor: "pointer" }}
                        onClick={(e) =>
                          handleProductIntoWishlist(product, selectedColor)
                        }
                      >
                        <div>
                          <MdFavoriteBorder fontSize={16} />
                        </div>
                        <div className="px-1">
                          <small className="text-decoration-underline font-weight-bold">
                            Save to Wishlist
                          </small>
                        </div>
                      </div> */}

                      {product.product_descriptions.length > 0 && (
                        <div className="mt-4" style={{ borderRadius: "0.5%" }}>
                          <div>
                            <div
                              className="py-2 mb-0 pb-0 dark-anchor"
                              style={{ fontSize: "14px" }}
                            >
                              {product.product_descriptions?.map(
                                (description) => (
                                  <>
                                    <h5 style={{ fontWeight: "bold" }}>{description.title}</h5>
                                    <div
                                      dangerouslySetInnerHTML={{
                                        __html: description.description,
                                      }}
                                    />
                                  </>
                                )
                              )}
                            </div>
                          </div>
                        </div>
                      )}
                      <div className="container-fluid px-4 mb-2 align-items-center">
                        <div style={{ fontSize: "14px" }}>
                          Share with friends :
                        </div>
                        <div style={{ clear: "both" }}>
                          <div
                            className="at-resp-share-element at-style-responsive"
                            role="region"
                          >
                            <SocialShare pageTitle={product.name} />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* {width >= 768 && (
                <div className="container-fluid img img-fluid px-3 mt-3 mb-3">
                  <img
                    src={productbanner}
                    alt=""
                    className="img-fluid"
                    style={{
                      width: `${
                        width >= 768 && width <= 1100 ? "100%" : "60%"
                      }`,
                    }}
                  />
                </div>
              )} */}
              {/* <div className="container-fluid d-flex px-4 mt-4 flex-column flex-md-row">
                <div style={{ fontSize: "14px" }}>
                  <p>Check Estimated Delivery Date For your Location :</p>
                </div>
                <div className="px-md-2 mb-3 mb-md-0">
                  <input
                    aria-invalid="false"
                    id="standard-basic"
                    placeholder="Enter Pincode"
                    type="tel"
                    className="MuiInputBase-input slkder"
                  />
                </div>
                <div
                  className="px-md-2 mb-2 mb-md-0 "
                  style={{ marginTop: "-5px", fontSize: "12px" }}
                >
                  <button
                    type="button"
                    className="btn btn-dark"
                    style={{ fontSize: "12px" }}
                  >
                    Check Now
                  </button>
                </div>
              </div> */}

              <div className="container-fluid px-4 mt-2 col-sm-12 col-lg-12">
                <div dangerouslySetInnerHTML={{ __html: product.detail }} />
              </div>
              {/* {product.product_descriptions.length > 0 && (
                <div
                  className="container-fluid mt-4"
                  style={{ borderRadius: "0.5%" }}
                >
                  <div>
                    <div
                      className="py-2 m-3 mb-0 pb-0 dark-anchor"
                      style={{ fontSize: "14px" }}
                    >
                      <Tabs
                        defaultActiveKey={product.product_descriptions[0].title}
                      >
                        {product.product_descriptions?.map(
                          (description, index) => (
                            <Tab
                              eventKey={description.title}
                              title={description.title}
                              key={description.id}
                            >
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: description.description,
                                }}
                              />
                            </Tab>
                          )
                        )}
                      </Tabs>
                    </div>
                  </div>
                </div>
              )} */}
              <div className="pb-4">
                <hr
                  className="mx-3"
                  style={{ background: "rgb(196, 196, 196)", height: "3px" }}
                />

                <div className="d-flex justify-content-center my-3 similar-product-title">
                  SIMILAR PRODUCTS
                </div>
                {width <= 768 ? (
                  <>
                    {similarProducts.length > 0 && (
                      <MBSection
                        datam={similarProducts}
                        headline=""
                        descrive=""
                      />
                    )}
                  </>
                ) : (
                  <>
                    {similarProducts.length > 0 && (
                      <Section datam={similarProducts} headline="" />
                    )}
                  </>
                )}
                {/* <hr
                  className="mx-3 mt-md-5"
                  style={{ background: "rgb(196, 196, 196)", height: "3px" }}
                /> */}

                {/* <div className="d-flex justify-content-center my-3 similar-product-title">
                  SHOP FROM RECENTLY VIEWED
                  <MdLocalMall />
                </div> */}

                {/* <BestSeller datam={recentlyViewd} title="Similar Produtcs" /> */}
              </div>
            </div>
          </>
        )}
      </div>
      <MessengerCustomerChat pageId="" appId="" />

      {width >= 768 ? (
        <Footer />
      ) : (
        <>
          <Footer />
          <MBFooter />
          {/* <AddToCartButton /> */}
        </>
      )}
    </>
  );
}
