import Bereadcrumb from "../components/Bereadcrumb";
import couponIcon from "../images/ic_apply_coupon.png";
import arrowIcon from "../images/brandArrow.svg";
import shoppingCartIcon from "../images/shopping_cart.svg";
import OffersPriceDetailsIcon from "../images/Offers_price_details.svg";
import OfferIcon from "../images/Offers_icon.svg";
import PriceDetailsIcon from "../images/PriceDetails.svg";
import CartSubtotalIcon from "../images/CartSubtotal.svg";
import Shipping_CostIcon from "../images/Shipping_Cost.svg";
import DiscountIcon from "../images/Discount.svg";
import AmountPayableIcon from "../images/AmountPayable.svg";
import GiftCardIcon from "../images/GiftCard.svg";
import Collapse from "react-bootstrap/Collapse";
import { useState, useEffect } from "react";
import { MdKeyboardArrowDown, MdKeyboardArrowUp } from "react-icons/md";
import CartProduct from "../components/CartProduct";
import withCart from "../HOCs/withCart";
import Footer from "../components/Footer";
import Navbar from "../components/Navbar";
import { useWebsite } from "../context/WebsiteContext";
import { numberWithSpaces } from "../lib/functions";
import Select from "react-select";
import useDeliveryCharge from "../hooks/useDeliveryCharge";
import { Link, useNavigate } from "react-router-dom";
import { callToast, getValueLocalStorage } from "../lib/functions";
import axios from "../lib/axiosConfig";
import useWidth from "../hooks/useWidth";
import MBNavbar from "../components/MBNavbar";
import MBFooter from "../components/MBFooter";
import bkashLogo from '../../src/images/bkash-logo.png'
import cod from '../../src/images/cod.png'


import {
    ErrorMessage,
    FastField,
    Field,
    FieldArray,
    Form,
    Formik,
} from "formik";

import * as Yup from "yup";
import ReactPixel from "react-facebook-pixel";
import { useCart } from "../context/CartContext";

// Initialize ReactPixel with your Pixel ID
ReactPixel.init("540109202031009");

function GuestShipping() {
    const width = useWidth();
    const navigate = useNavigate();
    const { loading, error, deliveryCharges } = useDeliveryCharge();
    const { setCarts } = useCart();

    const {
        isLoggedInState,
        cartDispatch,
        setTotalCartQuantity,
        setTotalCartAmount,
        cartState,
        totalCartQuantity,
        totalCartAmount,
        userInfo,
        setUserInfo,
        deliveryChangeInfo,
        setDeliveryChangeInfo,
    } = useWebsite();

    useEffect(() => {
        ReactPixel.pageView(); // For tracking page view
    }, []);

    const [initialValues, setInitialValues] = useState({
        full_name: "",
        contact_number: "",
        address: "",
    });

    const [deliveryChargeOption, setDeliveryChargeOption] = useState([]);
    const [currentDeliveryCharge, setCurrentDeliveryCharge] = useState(null);
    const [currentDeliveryChargeAmount, setCurrentDeliveryChargeAmount] =
        useState(0);

    const [fullNameCustomError, setFullNameCustomError] = useState("");
    const [addressCustomError, setAddressCustomError] = useState("");
    const [contactNumberCustomError, setContactNumberCustomError] = useState("");

    const [paymentMethod, setPaymentMethod] = useState('cash_on_delivery');

    const handlePaymentChange = (event) => {
        setPaymentMethod(event.target.value);
    };

    console.log("paymentMethod: ", paymentMethod);

    useEffect(() => {
        var arr = [];
        for (var i = 0, l1 = deliveryCharges.length; i < l1; i++) {
            var de = {
                value: deliveryCharges[i].id,
                label: deliveryCharges[i].name,
                charge: deliveryCharges[i].charge,
                name: deliveryCharges[i].name,
                id: deliveryCharges[i].id,
            };

            // if (i === 0) {
            //     setCurrentDeliveryCharge(deliveryCharges[i]);
            //     setCurrentDeliveryChargeAmount(deliveryCharges[i].charge);
            //     setDeliveryChangeInfo(de);
            // }
            arr[i] = de;
        }
        setDeliveryChargeOption(arr);
    }, [deliveryCharges]);

    // useEffect(() => {
    //     if (
    //         Object.keys(deliveryChangeInfo).length === 0 ||
    //         totalCartQuantity <= 0
    //     ) {
    //         navigate("/cart");
    //     }
    // }, [deliveryChangeInfo]);

    const validationSchema = Yup.object({
        full_name: Yup.string().required("Required Name!").nullable(),
        contact_number: Yup.string()
            .required("Required Contact Number!")
            .nullable(),
        address: Yup.string().required("Required Address!").nullable(),
    });


    let cartItems = localStorage.getItem("cartItem");
    if (cartItems) {
        try {
            cartItems = JSON.parse(cartItems);
        } catch (error) {
            console.error("Error parsing cart items:", error);
            cartItems = [];
        }
    } else {
        cartItems = [];
    }

    // Calculate the total amount
    const totalAmount = cartItems?.reduce((total, item) => {
        return total + (item?.quantity * item?.price);
    }, 0);



    const onSubmit = async (values, onSubmitProps) => {
        // var loggedToken = getValueLocalStorage();

        var cartIds = cartItems?.map((cart, i) => {
            return {
                product_id: cart?.product?.id,
                quantity: cart?.quantity,
                price: cart?.price,
            }
        });
        if (currentDeliveryChargeAmount <= 0) {
            callToast("error", "Delivery Address is required");
            return;
        }
        values.delivery_charge = currentDeliveryChargeAmount;
        values.items = cartIds;

        // values.delivery_id = deliveryChangeInfo.id;
        // values.delivery_charge = deliveryChangeInfo.charge;
        // values.cart_id = cartIds;

        if (paymentMethod === 'cash_on_delivery') {
            await axios({
                method: "post",
                url: "guest-checkout-order",
                data: values,
            })
                .then((response) => {
                    ReactPixel.track("Lead", { content_name: "Order Placed" });
                    ReactPixel.track("Purchase", { content_type: 'product' });
                    callToast("success", response.data.message);
                    setCarts([]);
                    localStorage.removeItem('cartItem');

                    return navigate("/");
                })
                .catch((err) => {
                    console.log("err", err);
                    setFullNameCustomError("");
                    setContactNumberCustomError("");
                    setAddressCustomError("");
                    if (err && err.response && err.response.data.errors) {
                        err.response.data.errors.forEach((item, index) => {
                            if (item.code === "full_name") {
                                setFullNameCustomError(item.message);
                            }
                            if (item.code === "contactNumber") {
                                setContactNumberCustomError(item.message);
                            }
                            if (item.code === "address") {
                                setAddressCustomError(item.setAddressCustomError);
                            }
                        });
                    } else {
                        callToast("error", err.data.message);
                    }
                });
        }
        else if (paymentMethod === 'bkash') {
            await axios({
                method: "post",
                url: "bkash/create-payment",
                data: values,
            })
                .then((response) => {
                    ReactPixel.track("Lead", { content_name: "Order Placed" });
                    ReactPixel.track("Purchase", { content_type: 'product' });
                    console.log("response", response);
                    callToast("success", response.data.message);
                    setCarts([]);
                    localStorage.removeItem('cartItem');

                    // return navigate("/");
                    window.location.replace(response.data.bkashURL);
                })
                .catch((err) => {
                    console.log("err", err);
                    setFullNameCustomError("");
                    setContactNumberCustomError("");
                    setAddressCustomError("");
                    if (err && err.response && err.response.data.errors) {
                        err.response.data.errors.forEach((item, index) => {
                            if (item.code === "full_name") {
                                setFullNameCustomError(item.message);
                            }
                            if (item.code === "contactNumber") {
                                setContactNumberCustomError(item.message);
                            }
                            if (item.code === "address") {
                                setAddressCustomError(item.setAddressCustomError);
                            }
                        });
                    } else {
                        callToast("error", err.data.message);
                    }
                });
        }




    };


    return (
        <>
            {width <= 768 ? (
                <>
                    <MBNavbar searchButton={false} />
                    <div className="font-weight-bold" style={{ paddingBottom: 20 }}>
                        {/* {isLoggedInState === true && ( */}
                        <div
                            className="shadow-lg mt-4 bg-white"
                            style={{ borderRadius: "20px" }}
                        >
                            <div className="row container-fluid">
                                <div className="col-12  py-3">
                                    <section className="mt-3">
                                        <div className="d-flex justify-content-between cart_orderSummary">
                                            <div>
                                                <span>
                                                    <h6>
                                                        <img
                                                            src={shoppingCartIcon}
                                                            width="20px"
                                                            height="20px"
                                                            alt="Shopping Cart Icon"
                                                        />
                                                        <span className="px-2"> Shipping </span>
                                                    </h6>
                                                </span>
                                            </div>
                                            <div>
                                                <h6 style={{ textDecoration: "underline" }}>
                                                    Cart Total : Tk{" "}
                                                    {
                                                        cartItems?.length > 0 ? numberWithSpaces(totalAmount) : 0
                                                    }
                                                    {/* {totalCartAmount
                                                        ? numberWithSpaces(totalCartAmount)
                                                        : 0} */}
                                                </h6>
                                            </div>
                                        </div>
                                    </section>
                                </div>

                                <div className="mt-2 px-2 bg-white border-top border-bottom ">
                                    <div className="pt-2 cartFontSubHeadings">
                                        <span className="cartPriceDetails">Price Details</span>
                                    </div>
                                    <h6>Select Shipping Method</h6>
                                    {deliveryChargeOption.length > 0 ? (
                                        <div
                                            className="d-flex mb-2"
                                            style={{ fontSize: "13px" }}
                                        >
                                            <div style={{ width: "100%" }}>
                                                <Select
                                                    options={deliveryChargeOption}
                                                    // defaultValue={deliveryChargeOption[0]}
                                                    onChange={(delivery) => {
                                                        setCurrentDeliveryCharge(delivery);
                                                        setCurrentDeliveryChargeAmount(
                                                            delivery.charge
                                                        );
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    ) : (
                                        ""
                                    )}
                                    <div className="mt-3 mb-3">
                                        <h6>Select Payment Method</h6>
                                        <div className="d-flex align-items-center mb-1">
                                            <input
                                                type="radio"
                                                id="cash_on_delivery"
                                                name="payment"
                                                value="cash_on_delivery"
                                                checked={paymentMethod === 'cash_on_delivery'}
                                                onChange={handlePaymentChange}
                                            />
                                            <label htmlFor="cash_on_delivery" className="ms-1"><img src={cod} style={{ width: "25px" }} alt="" />Cash on Delivery</label>
                                        </div>

                                        <div className="d-flex align-items-center">
                                            <input
                                                type="radio"
                                                id="bkash"
                                                name="payment"
                                                value="bkash"
                                                checked={paymentMethod === 'bkash'}
                                                onChange={handlePaymentChange}
                                            />
                                            <label htmlFor="bkash" className="ms-1"><img src={bkashLogo} style={{ width: "25px" }} alt="" />bKash</label>
                                        </div>
                                    </div>
                                    <div>
                                        <div className="d-flex justify-content-between ">
                                            <div>
                                                <div>
                                                    <span className=" text-secondary cartFontSubHeadings">
                                                        Subtotal
                                                    </span>
                                                </div>
                                            </div>
                                            <div>
                                                <div>
                                                    <span className="cartPriceValues">
                                                        Tk
                                                        {cartItems?.length > 0 ? numberWithSpaces(totalAmount) : 0}
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="d-flex justify-content-between ">
                                            <div>
                                                <div>
                                                    <span className=" text-secondary cartFontSubHeadings">
                                                        Shipping
                                                    </span>
                                                </div>
                                            </div>
                                            <div>
                                                <div>
                                                    <span className="cartPriceValues">
                                                        Tk {numberWithSpaces(currentDeliveryChargeAmount)}
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="d-flex justify-content-between ">
                                            <div>
                                                <div>
                                                    <span className=" text-secondary cartFontSubHeadings">
                                                        Discount
                                                    </span>
                                                </div>
                                            </div>
                                            <div>
                                                <div>
                                                    <span className="text-success cartFontSubHeadings">
                                                        0.00
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="d-flex justify-content-between py-1 border-top">
                                            <div>
                                                <div>
                                                    <div className="cartTotal">Amount Payable</div>
                                                </div>
                                            </div>
                                            <div>
                                                <div>
                                                    <div className="cartTotal">
                                                        Tk{" "}
                                                        {numberWithSpaces(
                                                            currentDeliveryChargeAmount +
                                                            totalAmount
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>


                                <div className="col-12 ">
                                    <section className="mt-3 mb-4 child-scroll-hide">
                                        <div
                                            style={{
                                                background: "#faf9f9",
                                                borderRadius: "10px",
                                            }}
                                            className="p-2 "
                                        >
                                            <Formik
                                                initialValues={initialValues}
                                                validationSchema={validationSchema}
                                                onSubmit={onSubmit}
                                                // validateOnChange={false}
                                                // validateOnBlur={false}
                                                // enableReinitialize={true}
                                                validateOnMount
                                            >
                                                {(formik) => {
                                                    return (
                                                        <Form id="my-form">
                                                            <div className="mb-3">
                                                                <label htmlFor="full_name">Full Name</label>
                                                                <Field
                                                                    type="text"
                                                                    id="full_name"
                                                                    name="full_name"
                                                                    className="form-control"
                                                                />
                                                                {/* <ErrorMessage name="full_name">
                                    {(msg) => (
                                      <div className="error">{msg}</div>
                                    )}
                                  </ErrorMessage> */}
                                                                {formik.touched.full_name &&
                                                                    formik.errors.full_name && (
                                                                        <div className="error">
                                                                            {formik.errors.full_name}
                                                                        </div>
                                                                    )}
                                                                {fullNameCustomError !== "" && (
                                                                    <div className="error">
                                                                        {fullNameCustomError}
                                                                    </div>
                                                                )}
                                                            </div>
                                                            <div className="mb-3">
                                                                <label htmlFor="contact_number">
                                                                    Contact Number
                                                                </label>
                                                                <Field
                                                                    type="text"
                                                                    id="contact_number"
                                                                    name="contact_number"
                                                                    className="form-control"
                                                                />
                                                                <ErrorMessage name="contact_number">
                                                                    {(msg) => (
                                                                        <div className="error">{msg}</div>
                                                                    )}
                                                                </ErrorMessage>
                                                                {contactNumberCustomError !== "" && (
                                                                    <div className="error">
                                                                        {contactNumberCustomError}
                                                                    </div>
                                                                )}
                                                            </div>
                                                            <div className="mb-3">
                                                                <label htmlFor="address">Address</label>
                                                                <Field
                                                                    type="text"
                                                                    id="address"
                                                                    name="address"
                                                                    className="form-control"
                                                                />
                                                                <ErrorMessage name="address">
                                                                    {(msg) => (
                                                                        <div className="error">{msg}</div>
                                                                    )}
                                                                </ErrorMessage>
                                                                {addressCustomError !== "" && (
                                                                    <div className="error">
                                                                        {addressCustomError}
                                                                    </div>
                                                                )}
                                                            </div>
                                                        </Form>
                                                    );
                                                }}
                                            </Formik>
                                        </div>
                                    </section>

                                    {/* <div style={{ height: 50 }}></div> */}
                                    <section className="my-3 py-2">
                                        <div>
                                            <div>
                                                <div
                                                    className="d-flex border "
                                                    style={{ fontSize: "13px" }}
                                                >
                                                    <div className="flex-grow-1">
                                                        <button
                                                            className="btn btn-dark form-control py-2"
                                                            style={{ fontSize: "13px" }}
                                                            form="my-form"
                                                            type="submit"
                                                        >
                                                            Confirm to place order
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </section>
                                </div>
                            </div>
                        </div>
                        {/* )} */}
                    </div>
                    <MBFooter />
                </>
            ) : (
                <>
                    <Navbar />
                    <div style={{ overflowX: "hidden" }} className="pb-3">
                        <div>
                            <Bereadcrumb pages={[{ path: "/guest-shipping", name: "Shipping" }]} />
                            <div
                                className="shadow-lg mt-4 mx-3 bg-white"
                                style={{ borderRadius: "20px" }}
                            >
                                <div className="row container-fluid">
                                    <div className="col-12 col-lg-6 px-4  ">
                                        <section className="mt-3">
                                            <div className="d-flex justify-content-between cart_orderSummary">
                                                <div>
                                                    <span>
                                                        <h6>
                                                            <img
                                                                src={shoppingCartIcon}
                                                                width="20px"
                                                                height="20px"
                                                                alt="Shopping Cart Icon"
                                                            />
                                                            <span className="px-2"> Shipping </span>
                                                        </h6>
                                                    </span>
                                                </div>
                                                <div>
                                                    <h6 style={{ textDecoration: "underline" }}>
                                                        Cart Total : Tk{" "}
                                                        {
                                                            cartItems?.length > 0 ? numberWithSpaces(totalAmount) : 0
                                                        }
                                                        {/* {totalCartAmount
                                                            ? numberWithSpaces(totalCartAmount)
                                                            : 0} */}
                                                    </h6>
                                                </div>
                                            </div>
                                        </section>
                                        <section className="mt-3 mb-4 child-scroll-hide">
                                            <div
                                                style={{
                                                    background: "#faf9f9",
                                                    borderRadius: "10px",
                                                }}
                                                className="p-2 "
                                            >
                                                <Formik
                                                    initialValues={initialValues}
                                                    validationSchema={validationSchema}
                                                    onSubmit={onSubmit}
                                                    validateOnChange={false}
                                                    validateOnBlur={false}
                                                    enableReinitialize={true}
                                                >
                                                    {(formik) => {
                                                        return (
                                                            <Form id="my-form">
                                                                <div className="mb-3">
                                                                    <label htmlFor="full_name">Full Name</label>
                                                                    <Field
                                                                        type="text"
                                                                        id="full_name"
                                                                        name="full_name"
                                                                        className="form-control"
                                                                    />
                                                                    <ErrorMessage name="full_name">
                                                                        {(msg) => (
                                                                            <div className="error">{msg}</div>
                                                                        )}
                                                                    </ErrorMessage>
                                                                    {fullNameCustomError !== "" && (
                                                                        <div className="error">
                                                                            {fullNameCustomError}
                                                                        </div>
                                                                    )}
                                                                </div>
                                                                <div className="mb-3">
                                                                    <label htmlFor="contact_number">
                                                                        Contact Number
                                                                    </label>
                                                                    <Field
                                                                        type="text"
                                                                        id="contact_number"
                                                                        name="contact_number"
                                                                        className="form-control"
                                                                    />
                                                                    <ErrorMessage name="contact_number">
                                                                        {(msg) => (
                                                                            <div className="error">{msg}</div>
                                                                        )}
                                                                    </ErrorMessage>
                                                                    {contactNumberCustomError !== "" && (
                                                                        <div className="error">
                                                                            {contactNumberCustomError}
                                                                        </div>
                                                                    )}
                                                                </div>
                                                                <div className="mb-3">
                                                                    <label htmlFor="address">Address</label>
                                                                    <Field
                                                                        type="text"
                                                                        id="address"
                                                                        name="address"
                                                                        className="form-control"
                                                                    />
                                                                    <ErrorMessage name="address">
                                                                        {(msg) => (
                                                                            <div className="error">{msg}</div>
                                                                        )}
                                                                    </ErrorMessage>
                                                                    {addressCustomError !== "" && (
                                                                        <div className="error">
                                                                            {addressCustomError}
                                                                        </div>
                                                                    )}
                                                                </div>
                                                            </Form>
                                                        );
                                                    }}
                                                </Formik>
                                            </div>

                                            {/* <div style={{ height: 400 }}>
                                                {userInfo && userInfo.customer_delivery_addresses && (
                                                    <section className="px-2 my-2 pt-2">
                                                        <div className="row">
                                                            {userInfo.customer_delivery_addresses?.map(
                                                                (delivery_address) => (
                                                                    <div
                                                                        className="col-lg-6 mt-3"
                                                                        key={delivery_address.id}
                                                                        onClick={() =>
                                                                            changeDeliveryAddress(delivery_address)
                                                                        }
                                                                    >
                                                                        <div className="card">
                                                                            <h5 className="card-header">
                                                                                {delivery_address.full_name}
                                                                            </h5>
                                                                            <div className="card-body">
                                                                                <h5 className="card-title">
                                                                                    {delivery_address.contact_number}
                                                                                </h5>
                                                                                <p className="card-text">
                                                                                    {delivery_address.address}
                                                                                </p>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                )
                                                            )}
                                                        </div>
                                                    </section>
                                                )}
                                            </div> */}
                                        </section>
                                    </div>
                                    <div className="col-12 col-lg-6 py-3">
                                        <section>
                                            <div className="d-flex justify-content-between cart_orderSummary">
                                                <div>
                                                    <span>
                                                        <h6>
                                                            <img
                                                                src={OffersPriceDetailsIcon}
                                                                width="20px"
                                                                height="20px"
                                                                alt=""
                                                            />
                                                            <span className="px-2">
                                                                Offers and Price Details
                                                            </span>
                                                        </h6>
                                                    </span>
                                                </div>
                                            </div>
                                        </section>
                                        <div
                                            style={{ background: "#faf9f9", borderRadius: "10px" }}
                                            className="p-2 "
                                        >
                                            <section>
                                                <div className="d-flex">
                                                    <div>
                                                        <img
                                                            src={PriceDetailsIcon}
                                                            width="20px"
                                                            height="20px"
                                                            alt=""
                                                        />
                                                    </div>
                                                    <div className="px-2 pt-1">
                                                        <p style={{ fontSize: "13px" }}>
                                                            <span style={{ textDecoration: "underline" }}>
                                                                Price
                                                            </span>
                                                            <span> </span> Details
                                                        </p>
                                                    </div>
                                                </div>
                                            </section>
                                            <section className="bg-white rounded">
                                                <div className="container-fluid py-2">
                                                    <h6>Select Shipping Method</h6>
                                                    {deliveryChargeOption.length > 0 ? (
                                                        <div
                                                            className="d-flex mb-2"
                                                            style={{ fontSize: "13px" }}
                                                        >
                                                            <div style={{ width: "15rem" }}>
                                                                <Select
                                                                    options={deliveryChargeOption}
                                                                    // defaultValue={deliveryChargeOption[0]}
                                                                    onChange={(delivery) => {
                                                                        setCurrentDeliveryCharge(delivery);
                                                                        setCurrentDeliveryChargeAmount(
                                                                            delivery.charge
                                                                        );
                                                                    }}
                                                                />
                                                            </div>
                                                        </div>
                                                    ) : (
                                                        ""
                                                    )}
                                                    <div className="mt-3 mb-3">
                                                        <h6>Select Payment Method</h6>
                                                        <div className="d-flex align-items-center mb-1">
                                                            <input
                                                                type="radio"
                                                                id="cash_on_delivery"
                                                                name="payment"
                                                                value="cash_on_delivery"
                                                                checked={paymentMethod === 'cash_on_delivery'}
                                                                onChange={handlePaymentChange}
                                                            />
                                                            <label htmlFor="cash_on_delivery" className="ms-1"> <img src={cod} style={{ width: "25px" }} alt="" />Cash on Delivery</label>
                                                        </div>

                                                        <div className="d-flex align-items-center">
                                                            <input
                                                                type="radio"
                                                                id="bkash"
                                                                name="payment"
                                                                value="bkash"
                                                                checked={paymentMethod === 'bkash'}
                                                                onChange={handlePaymentChange}
                                                            />
                                                            <label htmlFor="bkash" className="ms-1"> <img src={bkashLogo} style={{ width: "25px" }} alt="" /> bKash</label>
                                                        </div>
                                                    </div>
                                                    <div
                                                        className="d-flex mb-2"
                                                        style={{ fontSize: "13px" }}
                                                    >
                                                        <span>
                                                            <img
                                                                src={CartSubtotalIcon}
                                                                width="15px"
                                                                height="15px"
                                                                alt=""
                                                            />
                                                        </span>


                                                        {/* <div className="d-flex">
                                                            <div style={{ width: "100%" }}>
                                                                {deliveryChargeOption.length > 0 && (
                                                                    <Select
                                                                        options={deliveryChargeOption}
                                                                        defaultValue={deliveryChargeOption[0]}
                                                                        onChange={(delivery) => {
                                                                            setCurrentDeliveryCharge(delivery);
                                                                            setCurrentDeliveryChargeAmount(delivery.charge);
                                                                            setDeliveryChangeInfo(delivery);
                                                                        }}
                                                                    />
                                                                )}
                                                            </div>
                                                        </div> */}

                                                        <span className="px-2">Cart Sub Total: </span>
                                                        <span className="px-5">
                                                            Tk {cartItems?.length > 0 ? numberWithSpaces(totalAmount) : 0}
                                                        </span>
                                                    </div>

                                                    <div
                                                        className="d-flex mb-2"
                                                        style={{
                                                            fontSize: "13px",
                                                            display: "inline-block",
                                                        }}
                                                    >
                                                        <span>
                                                            <img
                                                                src={Shipping_CostIcon}
                                                                width="15px"
                                                                height="15px"
                                                                alt=""
                                                            />
                                                        </span>
                                                        <span className="px-2">Shipping Cost: </span>
                                                        <span className="px-5">
                                                            Tk{" "}
                                                            {numberWithSpaces(currentDeliveryChargeAmount)}
                                                        </span>
                                                    </div>
                                                    <div
                                                        className="d-flex mb-2"
                                                        style={{ fontSize: "13px" }}
                                                    >
                                                        <span>
                                                            <img
                                                                src={DiscountIcon}
                                                                width="15px"
                                                                height="15px"
                                                                alt=""
                                                            />
                                                        </span>
                                                        <span className="px-2">Discount Applied: </span>
                                                        <span className="px-4">
                                                            <span className="px-1 text-success">
                                                                Tk 0.00
                                                            </span>
                                                        </span>
                                                    </div>
                                                    <div>
                                                        <div
                                                            className="d-flex "
                                                            style={{ fontSize: "13px" }}
                                                        >
                                                            <span>
                                                                <img
                                                                    src={AmountPayableIcon}
                                                                    width="15px"
                                                                    height="15px"
                                                                    alt=""
                                                                />
                                                            </span>
                                                            <span className="px-2">Amount Payable: </span>
                                                            <span className="px-4">
                                                                <span className="px-2 fw-bold">
                                                                    Tk{" "}
                                                                    {numberWithSpaces(
                                                                        currentDeliveryChargeAmount +
                                                                        totalAmount
                                                                    )}
                                                                </span>
                                                            </span>
                                                        </div>
                                                        <div
                                                            style={{ fontSize: "10.5px" }}
                                                            className="px-4 "
                                                        >
                                                            <span>
                                                                Including Tk{" "}
                                                                {numberWithSpaces(
                                                                    currentDeliveryChargeAmount +
                                                                    totalAmount
                                                                )}{" "}
                                                                in taxes
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </section>
                                            <section className="my-3 py-2">
                                                <div>
                                                    <div>
                                                        <div
                                                            className="d-flex border "
                                                            style={{ fontSize: "13px" }}
                                                        >
                                                            <div className="flex-grow-1">
                                                                <button
                                                                    className="btn btn-dark form-control py-2"
                                                                    style={{ fontSize: "13px" }}
                                                                    form="my-form"
                                                                    type="submit"
                                                                >
                                                                    Confirm to place order
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </section>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Footer />
                </>
            )}
        </>
    );
}

export default GuestShipping;
