import Bereadcrumb from "../components/Bereadcrumb";

import Footer from "../components/Footer";
import Navbar from "../components/Navbar";
import CustomerSideBar from "../components/CustomerSideBar";
import { useWebsite } from "../context/WebsiteContext";
import { Link } from "react-router-dom";
import Table from "react-bootstrap/Table";
import { useEffect, useState } from "react";
import axios from "../lib/axiosConfig";
import { numberWithSpaces, getValueLocalStorage } from "../lib/functions";
import MBNavbar from "../components/MBNavbar";

function Order() {
  const { userInfo } = useWebsite();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const [orderList, setOrderList] = useState([]);

  useEffect(() => {
    async function fetchOrders() {
      var loggedToken = getValueLocalStorage("loggedToken");
      try {
        await axios({
          method: "post",
          url: "order-list",
          headers: { Authorization: `Bearer ${loggedToken}` },
        })
          .then((response) => {
            setOrderList(response.data.orderLists);
            setLoading(false);
            setError(false);
          })
          .catch((err) => {
            setOrderList([]);
            setLoading(false);
            setError(err);
          });
      } catch (error) {
        setLoading(false);
        setError("Connection Error");
      }
    }

    fetchOrders();

    return () => {
      setOrderList([]);
      setError("");
      setLoading(true);
    };
  }, []);

  return (
    <>
      {/* <Navbar /> */}
      <MBNavbar searchButton={false} />
      <div style={{ overflowX: "hidden" }} className="pb-3">
        <div>
          <Bereadcrumb pages={[{ path: "/orders", name: "Order" }]} />
          <div
            className="shadow-lg mt-4 mx-3 bg-white d-flex justify-content-center"
            style={{ borderRadius: "20px" }}
          >
            <div className="row container-fluid">
              <div className="col-12 col-lg-2 px-4 ">
                <section className="mt-3">
                  <CustomerSideBar />
                </section>
              </div>
              <div className="col-12 col-lg-10 py-3">
                <section>
                  <div className="d-flex justify-content-between cart_orderSummary">
                    <div>
                      <span>
                        <h6>
                          <span className="px-2">Order List</span>
                        </h6>
                      </span>
                    </div>
                  </div>
                </section>
                <div
                  style={{
                    background: "#faf9f9",
                    borderRadius: "10px",
                  }}
                  className="p-2 "
                >
                  {orderList && orderList.length > 0 && (
                    <Table striped responsive>
                      <thead>
                        <tr>
                          <th className="text-center">#</th>
                          <th className="text-center">Order No</th>
                          <th className="text-center">Date/Time</th>
                          <th className="text-center">Status</th>
                          <th className="text-center">Quantity</th>
                          <th className="text-center">Amount</th>
                          <th className="text-center">Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {orderList?.map((order, i) => (
                          <tr key={order.id}>
                            <th className="text-center">{i + 1}</th>
                            <th className="text-center">{order.order_no}</th>
                            <th className="text-center">
                              {order.order_date_time}
                            </th>
                            <th className="text-center">
                              {order.order_status}
                            </th>
                            <th className="text-center">
                              {order.bill_quantity}
                            </th>
                            <th className="text-center">
                              {numberWithSpaces(order.bill_amount)}
                            </th>
                            <th className="text-center">
                              <Link
                                to={`/orders/${order.id}`}
                                className="btn btn-primary"
                              >
                                Detail
                              </Link>
                            </th>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default Order;
