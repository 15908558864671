import { Link } from "react-router-dom";
import { useWebsite } from "../context/WebsiteContext";

const navlinks = [
  {
    id: 4239849,
    name: "OFFERS",
    path: "/offers",
  },
  {
    id: 7734589743,
    name: "MORE",
    path: "/offers",
  },
];

export default function NavLink() {
  const { categories } = useWebsite();
  return (
    <div className="d-flex justify-content-between bg-light">
      <div className="col-9 col-md-9 col-lg-9 col-xl-9 mx-auto px-3 d-flex justify-content-center navHeading white-spacing-none">
        {categories?.length > 0 &&
          categories?.map((category, ci) => (
            <div className="menuDropdown mx-4" key={ci}>
              <Link
                to={`/${category.slug}`}
                className="fw-bold position-relative py-3 d-block level1"
                style={{ whiteSpace: "nowrap", fontSize: "14px", color: "black" }}
              >
                {category.name}
              </Link>
              {category.sub_categories?.length > 0 && (
                <div className="menuDropdownContent">
                  {category.sub_categories?.map((sub_category, sci) => (
                    <div className="px-2 py-2 d-flex" key={sci}>
                      <Link to={`/${category.slug}/${sub_category.slug}`}>
                        <span
                          className="level3 d-flex flex-wrap position-relative"
                          style={{ maxWidth: "10rem", fontSize: "14px", color: "black" }}
                        >
                          {sub_category.name}
                        </span>
                      </Link>
                    </div>
                  ))}
                </div>
              )}
            </div>
          ))}

        {navlinks?.map((navlink1) => (
          <div className="menuDropdown mx-4" key={navlink1.id}>
            <Link
              to={navlink1.path}
              className="fw-bold position-relative py-3 d-block"
              style={{ whiteSpace: "nowrap", color: "black" }}
            >
              {navlink1.name}
            </Link>
            {navlink1.sublinks && (
              <div className="menuDropdownContent">
                {navlink1.sublinks?.map((navlink2) => (
                  <div
                    className="anotherMenuDropdown d-flex level2"
                    key={navlink2.id}
                  >
                    <div className="px-3 py-2 mx-2 my-2">
                      <Link to={navlink2.path}>
                        <span
                          className="d-flex flex-wrap position-relative"
                          style={{ textShadow: "none", color: "black" }}
                        >
                          {navlink2.name}
                        </span>
                      </Link>
                      {navlink2.sublinks && (
                        <div className="anotherMenuDropdownContent py-3">
                          {navlink2.sublinks?.map((navlink3) => (
                            <div className="px-2 py-2 d-flex" key={navlink3.id}>
                              <Link to={navlink3.path}>
                                <span
                                  className="level3 d-flex flex-wrap position-relative"
                                  style={{ maxWidth: "10rem", color: "black" }}
                                >
                                  {navlink3.name}
                                </span>
                              </Link>
                            </div>
                          ))}
                        </div>
                      )}
                    </div>
                  </div>
                ))}
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
}
