import image from '../images/image1x1.jpg'
import image1 from '../images/image1x1-1.jpg'
import Offerblog from '../components/OfferBlog'
import Footer from '../components/Footer'
import MBFooter from '../components/MBFooter'
import useWidth from '../hooks/useWidth'
import BackNavbar from '../components/BackNavbar'
import Navbar from '../components/Navbar'
import { useEffect, useState } from "react";
import axios from "../lib/axiosConfig";
import LoadingSpinner from '../components/LoadingSpinner'
import ReactPixel from "react-facebook-pixel";

// Initialize ReactPixel with your Pixel ID
ReactPixel.init("540109202031009");

const offersData = [
	{
		id: 39493843,
		path: '/offers-vendor',
		image: image1,
		code: 'GJMSO',
	},
	{
		id: 9348739,
		path: '/offers-vendor',
		image: image,
		code: 'GSLER',
	},
	{
		id: 923847832987,
		path: '/offers-vendor',
		image: image1,
		code: 'LSKDJ',
	},
	{
		id: 23890473098,
		path: '/offers-vendor',
		image: image,
		code: 'GRFHF',
	},
	{
		id: 39840239,
		path: '/offers-vendor',
		image: image1,
		code: 'RWERD',
	},
	{
		id: 32084039,
		path: '/offers-vendor',
		image: image,
		code: 'ASDE',
	},
]



export default function Offers() {
	const [copy, setCopy] = useState('')
	const width = useWidth()

	const [loading, setLoading] = useState(true);
	const [error, setError] = useState("");
	const [offers, setOffers] = useState({});

	useEffect(() => {
		ReactPixel.pageView(); // For tracking page view
	}, []);

	useEffect(() => {
		async function fetchOffers() {
			try {
				setLoading(true);
				setError(false);
				await axios
					.get("all-offers")
					.then((response) => {
						setOffers(response.data.data);
						setLoading(false);
						setError(false);
					})
					.catch((err) => {
						setOffers({});
						setLoading(false);
						setError(err);
					});
			}
			catch (err) {
				setOffers({});
				setLoading(false);
				setError(err);
			}
		}
		fetchOffers();
	}, []);

	return (
		<>
			{width >= 768 ? <Navbar /> : <BackNavbar backurl="/" title="Offers" />}

			<div className="container-fluid">
				<div
					className={
						width >= 768
							? `fs-2 d-flex justify-content-center pt-1 mb-3`
							: `forYouOffers text-center py-2 rounded-3 mt-1`
					}
				>
					General Offers
				</div>
			</div>

			<div className="pb-5 pb-md-0">
				<div className="row pt-2 container-fluid bg-white pe-0 pb-3 pb-md-0">

					{offers.length > 0 ? (
						<>
							{offers?.map(offer => (
								<div className="col-md-6 col-lg-4 my-2" key={offer.id}>
									<Offerblog offer={offer} copy={copy} setCopy={setCopy} />
								</div>
							))}
						</>
					) : (
						<>
							{loading && !error && (<div><LoadingSpinner /></div>)}
						</>
					)}
					{!loading && offers.length === 0 && (<div> No Data found</div>)}

					{loading && error && (<div> There is an error</div>)}
				</div>
			</div>

			{width >= 768 ? <Footer /> : <MBFooter />}
		</>
	)
}
