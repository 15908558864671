import React, { useEffect, useRef, useState } from "react";
import * as Yup from "yup";
import img from "../images/pic.png";

import {
  ErrorMessage,
  FastField,
  Field,
  FieldArray,
  Form,
  Formik,
} from "formik";
import axios from "axios";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";
import ProductDetailsPreview from "../components/ProductDetailsPreview";
import { MdCall } from "react-icons/md";
import ReactPixel from "react-facebook-pixel";

// Initialize ReactPixel with your Pixel ID
ReactPixel.init("540109202031009");

const validationSchema = Yup.object().shape({
  name: Yup.string().required("আপনার নাম প্রয়োজন"),
  number: Yup.string().required("মোবাইল নাম্বার প্রয়োজন"),
  address: Yup.string().required("আপনার ঠিকানা প্রয়োজন"),
  selectedShipping: Yup.string().required("শিপিং মেথড প্রয়োজন"),
});

const initialValues = {
  name: "",
  number: "",
  address: "",
  selectedShipping: "",
  selectedDelivery: "delivery",
};

const ProductDetails = () => {
  const [quantity, setQuantity] = useState(1);
  const [shipping, setShipping] = useState([]);
  const [product, setProduct] = useState({});
  const [shippingCharge, setShippingCharge] = useState(0);
  const [imagePreview, setImagePreview] = useState("");
  const [current, setCurrent] = useState(0);
  const [allImage, setAllImage] = useState([]);
  const [showPreviewImages, setShowPreviewImages] = useState([]);
  const [loadingBtn, setLoadingBtn] = useState(false);

  const { productName } = useParams();

  useEffect(() => {
    ReactPixel.pageView(); // For tracking page view
  }, []);

  const changeImage = (e) => {
    setImagePreview(e.currentTarget.src);
  };

  useEffect(() => {
    setShowPreviewImages(allImage?.slice(0, 3));
  }, [allImage]);

  const handleKeyboardArrowUpEvents = (e) => {
    if (current > 0) {
      setShowPreviewImages(allImage?.slice(current - 3, current));
      setCurrent((previousState) => previousState - 3);
    } else {
      setShowPreviewImages(allImage?.slice(0, 3));
      setCurrent(0);
    }
  };

  const handleKeyboardArrowDownEvents = (e) => {
    if (allImage.length > current + 3) {
      setShowPreviewImages(allImage?.slice(current + 3, current + 3 + 3));
      setCurrent((previousState) => previousState + 3);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          "https://www.bdbeautyglamorous.com/web/api/deliveryCharges"
        );

        setShipping(response.data.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `https://www.bdbeautyglamorous.com/web/api/single-products/${productName}`
        );

        setProduct(response.data);
        setAllImage(response?.data?.data?.product_extra_images);
        setShowPreviewImages(
          response?.data?.data?.product_extra_images?.slice(
            current + 3,
            current + 3 + 3
          )
        );
        setImagePreview(response?.data?.data?.image_path);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  const handleQuantity = (type) => {
    if (type === "increment") {
      setQuantity(quantity + 1);
    } else {
      if (quantity > 1) {
        setQuantity(quantity - 1);
      }
    }
  };

  const handlePlaceOrder = async (values, formik) => {
    setLoadingBtn(true);

    const newData = {
      full_name: values.name,
      contact_number: values.number,
      address: values.address,
      delivery_charge: shippingCharge,
      bill_quantity: quantity,
      bill_amount: product?.data?.sale_rate - product?.data?.discount_amount,
      product_id: product?.data?.id,
    };

    axios
      .post(
        "https://www.bdbeautyglamorous.com/web/api/single-product-order",
        newData
      )
      .then((res) => {
        toast.success(res.data.message);
        formik.resetForm();
        setLoadingBtn(false);
        setQuantity(1);
        setShippingCharge(0);
        console.log(res.data);
      })
      .catch((error) => {
        console.log(error);
        setLoadingBtn(false);
      });
  };

  return (
    <div style={{ background: "#F5F5F5", padding: "0 0px 50px" }}>
      <div className="container px-0" style={{ background: "#fff" }}>
        <div
          className="d-flex justify-content-between align-items-center"
          style={{ background: "#C3B9B9", color: "#000", padding: "5px 20px" }}
        >
          <div>
            <img
              src="/logo.png"
              // style={{ width: "190px", height: "80px" }}
              className="singlePageLogo"
              alt="Logo"
            />
          </div>
          <div className="logo-name">
            <h2 style={{ fontFamily: "Playball" }}>BD Beauty Glamorous Ltd.</h2>
          </div>
          <div className="number">
            <b>
              <p className="d-flex align-items-center">
                <MdCall style={{ fontSize: "20px", marginRight: "3px" }} />
                <span>01713-227555</span>
              </p>
            </b>
          </div>
        </div>
        <div className="row">
          <div className="col-md-10 mx-auto">
            <div className="div" style={{ padding: "30px 20px" }}>
              {/* title And Short Description */}
              <div>
                <h2 className="text-center" style={{ fontSize: "35px" }}>
                  {product?.data?.name}
                </h2>
                <p
                  style={{
                    fontSize: "18px",
                    letterSpacing: "0.5px",
                    fontFamily: "Noto Sans Bengali",
                    textAlign: "center",
                  }}
                  dangerouslySetInnerHTML={{ __html: product?.data?.detail }}
                ></p>
              </div>

              {/* Product Image */}
              <div className="row mt-4">
                <ProductDetailsPreview
                  preview={imagePreview}
                  // product={productDatam}
                  changeImage={changeImage}
                  all_images={showPreviewImages}
                  handleKeyboardArrowUpEvents={handleKeyboardArrowUpEvents}
                  handleKeyboardArrowDownEvents={handleKeyboardArrowDownEvents}
                />
              </div>
              <div className="d-flex justify-content-center mt-3">
                <button className="orderButton">
                  <a href="#form" className="text-black">
                    অর্ডার করতে চাই
                  </a>
                </button>
              </div>

              {/* Product Details */}

              {product?.data?.product_descriptions?.map((item) => (
                <div className="mt-4">
                  <h2
                    className="text-center font-bold py-2 mb-4"
                    style={{
                      fontSize: "25px",
                      background: "pink",
                      width: "100%",
                      borderRadius: "5px",
                      fontFamily: "Noto Sans Bengali",
                    }}
                  >
                    {item?.title}
                  </h2>
                  <p
                    style={{
                      fontSize: "18px",
                      letterSpacing: "0.5px",
                      fontFamily: "Noto Sans Bengali",
                    }}
                    dangerouslySetInnerHTML={{ __html: item?.description }}
                  ></p>{" "}
                </div>
              ))}
              <div className="d-flex justify-content-center mt-3">
                <button className="orderButton">
                  <a href="#form" className="text-black">
                    অর্ডার করতে চাই
                  </a>
                </button>
              </div>

              {/* Video */}
              <div className="d-flex justify-content-center mt-5">
                <iframe
                  //   width="80%"
                  //   height="550px"
                  src={product?.videoLink}
                  title="description"
                  className="mx-auto text-center videoLink"
                  frameBorder="0"
                  allowFullScreen
                ></iframe>
              </div>

              {/* Order Form */}
              <div className="mt-4" id="form">
                <h2
                  className="text-center font-bold py-2 mb-4"
                  style={{
                    fontSize: "25px",
                    background: "pink",
                    width: "100%",
                    borderRadius: "5px",
                    fontFamily: "Noto Sans Bengali",
                  }}
                >
                  {product?.data?.name} নেয়ার জন্য, নিচের ফর্মটি সম্পূর্ণ পূরণ
                  করুন
                </h2>

                <Formik
                  initialValues={initialValues}
                  validationSchema={validationSchema}
                  onSubmit={handlePlaceOrder}
                //   validateOnChange={false}
                //   validateOnBlur={false}
                >
                  {(formik) => {
                    return (
                      <Form>
                        <div className="row">
                          <div className="col-md-6 col-12">
                            <h3 className="mb-4 font-bold">Billing details</h3>
                            {/* Name */}
                            <div className="mb-3">
                              <label className="pb-2" htmlFor="name">
                                আপনার নাম *
                              </label>
                              <Field
                                type="text"
                                id="name"
                                name="name"
                                placeholder="আপনার নাম"
                                className="form-control"
                                style={{ width: "90%" }}
                              />
                              <ErrorMessage name="name">
                                {(msg) => <div className="error">{msg}</div>}
                              </ErrorMessage>
                            </div>
                            {/* Phone */}
                            <div className="mb-3">
                              <label className="pb-2" htmlFor="number">
                                মোবাইল নাম্বার *
                              </label>
                              <Field
                                type="text"
                                id="number"
                                name="number"
                                placeholder="মোবাইল নাম্বার"
                                className="form-control"
                                style={{ width: "90%" }}
                              />
                              <ErrorMessage name="number">
                                {(msg) => <div className="error">{msg}</div>}
                              </ErrorMessage>
                            </div>

                            {/* Address */}
                            <div className="mb-3">
                              <label className="pb-2" htmlFor="address">
                                আপনার ঠিকানা *
                              </label>
                              <Field
                                type="text"
                                id="address"
                                name="address"
                                placeholder="বাসার নং, রোড নং, থানা, জেলা"
                                className="form-control"
                                style={{ width: "90%" }}
                              />
                              <ErrorMessage name="address">
                                {(msg) => <div className="error">{msg}</div>}
                              </ErrorMessage>
                            </div>
                            {/* country / Region */}
                            <div className="mb-3">
                              <label className="pb-2" htmlFor="address">
                                Country / Region *
                              </label>
                              <p>
                                <b>Bangladesh</b>
                              </p>
                            </div>
                          </div>
                          <div className="col-md-6 col-12">
                            <h3 className="mb-4  font-bold">Your order</h3>
                            <div className="d-flex justify-content-between align-items-start">
                              <p className="font-bold">Product</p>
                              <p className="font-bold"> Subtotal</p>
                            </div>
                            <hr style={{ border: "1px dashed black" }} />
                            <div className="d-flex justify-content-between align-items-start">
                              <div className="d-flex align-items-center">
                                <img
                                  src={product?.data?.image_path}
                                  alt=""
                                  style={{
                                    width: "60px",
                                    height: "50px",
                                    borderRadius: "10px",
                                  }}
                                />
                                <div>
                                  <p className="font-bold ms-2 pb-0 mb-1">
                                    {product?.data?.name}{" "}
                                  </p>
                                  <div className="d-flex align-items-center ms-3">
                                    <span
                                      className="quantityButton"
                                      onClick={handleQuantity}
                                    >
                                      -
                                    </span>
                                    <span className="quantitInput">
                                      {quantity}
                                    </span>
                                    <span
                                      className="quantityButton"
                                      onClick={() =>
                                        handleQuantity("increment")
                                      }
                                    >
                                      +
                                    </span>
                                  </div>
                                </div>
                              </div>
                              <div className="align-items-center">
                                {/* <span
                                  className="quantityButton"
                                  onClick={handleQuantity}
                                >
                                  -
                                </span>
                                <span className="quantitInput">{quantity}</span>
                                <span
                                  className="quantityButton"
                                  onClick={() => handleQuantity("increment")}
                                >
                                  +
                                </span> */}
                                {product?.data?.discount_amount > 0 ? (
                                  <p className="ms-2 pt-2">
                                    <del>৳ {product?.data?.sale_rate}</del> ৳{" "}
                                    {parseFloat(
                                      product?.data?.sale_rate -
                                      product?.data?.discount_amount
                                    ).toFixed(2)}
                                  </p>
                                ) : (
                                  <p className="ms-2 pt-2">
                                    ৳{" "}
                                    {parseFloat(
                                      product?.data?.sale_rate
                                    ).toFixed(2)}
                                  </p>
                                )}
                              </div>
                            </div>
                            <hr style={{ border: "1px dashed black" }} />
                            <div className="d-flex justify-content-between align-items-start">
                              <p>Subtotal</p>
                              {product?.data?.discount_amount > 0 ? (
                                <p>
                                  {" "}
                                  ৳{" "}
                                  {parseFloat(
                                    (product?.data?.sale_rate -
                                      product?.data?.discount_amount) *
                                    quantity
                                  ).toFixed(2)}
                                </p>
                              ) : (
                                <p>
                                  {" "}
                                  ৳{" "}
                                  {parseFloat(
                                    product?.data?.sale_rate * quantity
                                  ).toFixed(2)}
                                </p>
                              )}
                            </div>

                            {/* Shipping */}
                            <div className="d-flex justify-content-between align-items-start mt-3">
                              <p>Shipping</p>
                              <div>
                                {shipping?.map((item) => (
                                  <>
                                    <label>
                                      <input
                                        type="radio"
                                        name="selectedShipping"
                                        value={item?.name}
                                        checked={
                                          formik.values.selectedShipping ===
                                          item?.name
                                        }
                                        onChange={(e) => {
                                          formik.handleChange(e);
                                          setShippingCharge(item?.charge);
                                        }}
                                        className="me-2"
                                      />
                                      {item?.name}: ৳ {item?.charge}
                                    </label>

                                    <br />
                                  </>
                                ))}
                                <ErrorMessage name="selectedShipping">
                                  {(msg) => <div className="error">{msg}</div>}
                                </ErrorMessage>
                                {/* <label>
                                  <input
                                    type="radio"
                                    name="selectedShipping"
                                    value="insideDhaka"
                                    checked={
                                      formik.values.selectedShipping ===
                                      "insideDhaka"
                                    }
                                    onChange={formik.handleChange}
                                    className="me-2"
                                  />
                                  ঢাকা সিটির মধ্যে: ৳ 50
                                </label>
                                <br />
                                <label>
                                  <input
                                    type="radio"
                                    name="selectedShipping"
                                    value="outsideDhaka"
                                    checked={
                                      formik.values.selectedShipping ===
                                      "outsideDhaka"
                                    }
                                    onChange={formik.handleChange}
                                    className="me-2"
                                  />
                                  ঢাকা সিটির বাইরে: ৳ 100
                                </label> */}
                              </div>
                            </div>

                            <hr style={{ border: "1px dashed black" }} />

                            {/* Total */}
                            <div className="d-flex justify-content-between align-items-start">
                              <p>Total</p>
                              {product?.data?.discount_amount > 0 ? (
                                <p>
                                  ৳
                                  {parseFloat(
                                    (product?.data?.sale_rate -
                                      product?.data?.discount_amount) *
                                    quantity +
                                    shippingCharge
                                  ).toFixed(2)}
                                </p>
                              ) : (
                                <p>
                                  ৳
                                  {parseFloat(
                                    product?.data?.sale_rate * quantity +
                                    shippingCharge
                                  ).toFixed(2)}
                                </p>
                              )}
                            </div>

                            <div
                              className="mb-4 py-4 px-3"
                              style={{ background: "#F7F7F7" }}
                            >
                              <label>
                                <input
                                  type="radio"
                                  name="selectedDelivery"
                                  value="delivery"
                                  checked={
                                    formik.values.selectedDelivery ===
                                    "delivery"
                                  }
                                  onChange={formik.handleChange}
                                  className="me-2"
                                />
                                Cash on delivery
                              </label>
                            </div>

                            {/* button */}
                            {
                              <button
                                type="submit"
                                className="detailsSubmitButton"
                                disabled={loadingBtn}
                              >
                                {loadingBtn ? "Processing..." : "Place Order"}
                              </button>
                            }
                          </div>
                        </div>
                      </Form>
                    );
                  }}
                </Formik>
              </div>

              {/* Footer */}
              <div className="d-flex justify-content-center mt-4 ">
                <p className="font-weight-bold" style={{ fontSize: "10px" }}>
                  <b>
                    কোন প্রশ্ন বা সাহায্যের প্রয়োজন হলে কল করুন - 01713-227555
                  </b>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductDetails;
