import { useEffect, useState } from "react";
import axios from "../lib/axiosConfig";

const useDeliveryCharge = () => {
	const [loading, setLoading] = useState(true);
	const [error, setError] = useState("");
	const [deliveryCharges, setDeliveryCharges] = useState([]);

	useEffect(() => {
		async function fetchDeliveryCharges() {
			try {
				await axios
					.get("all-delivery-charges")
					.then((response) => {
						setDeliveryCharges(response.data.data);
						setLoading(false);
						setError(false);
					})
					.catch((err) => {
						setDeliveryCharges([]);
						setLoading(false);
						setError(err);
					});
			} catch (error) {
				setLoading(false);
				setError("Connection Error");
			}
		}

		fetchDeliveryCharges();

		return () => {
			setDeliveryCharges([]);
			setError("");
			setLoading(true);
		};
	}, []);

	return {
		loading,
		error,
		deliveryCharges,
	};
};

export default useDeliveryCharge;
