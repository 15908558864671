import { useState, useEffect } from "react";
import lessThan from "../images/less-than.png";
import greaterThan from "../images/greater-than.png";
import SectionCard from "./SectionCard";
import useWidth from "../hooks/useWidth";
import { useSkipLower } from "../hooks/useSkipLower";
import { Link } from "react-router-dom";

/* Example of props.datam = [
  {
    path: '/products/aquaholic-overnight-water-mask', // products details apge
    image: 'https://cdn.shopify.com/s/files/1/0906/2558/products/sugar-cosmetics-charcoal-patrol-face-mask-12775752564819.jpg?v=1619113688',
    title: `Charcoal Patrol Face Mask`,
    price: 1697, // prodicut pricediscount:
    discount: {
      price: 1199,
      percentage: '29%',
    },
  },
  ...
]
*/

export default function Section({ datam, headline }) {
  const [currentIteration, setCurrentIteration] = useState(0);
  const [nextprev, setNextprev] = useState(false);
  const [sectionData, setSectionData] = useState([]);

  const skipLower = useSkipLower(headline?.toString());

  useEffect(() => {
    if (skipLower !== "allproducts") {
      setSectionData(datam?.slice(0, 4));
    } else {
      setSectionData(datam?.slice(0, 16));
    }
  }, [datam]);

  const handleItem = (direction) => {
    if (direction === "previous") {
      if (currentIteration > 0) {
        setSectionData(datam.slice(currentIteration - 4, currentIteration));
        setCurrentIteration((previousState) => previousState - 4);
      } else {
        setSectionData(datam.slice(0, 4));
        setCurrentIteration(0);
      }
    } else {
      if (datam?.length > currentIteration + 4) {
        setSectionData(
          datam.slice(currentIteration + 4, currentIteration + 4 + 4)
        );
        setCurrentIteration((previousState) => previousState + 4);
      } else {
        // console.log('nothings is left');
      }
    }
  };

  const width = useWidth();

  return (
    <div className={headline !== undefined ? "container-fluid" : ""}>
      {headline !== undefined && skipLower !== "allproducts" ? (
        <>
          <h3 className="my-4 headerTitle" style={{ fontWeight: 700 }}>{headline}</h3>
        </>
      ) : (
        <div className="row">
          <div className="col-md-10 mx-auto align-items-center">
            <div className="row align-items-center">
              <div className="col-md-11">
                <h3 className="my-4 headerTitle" style={{ fontWeight: 700 }}>{headline}</h3>
              </div>
              <div className="col-md-1">
                <Link to="/products">
                  <p
                    className="flex-end my-4 border border-black"
                    style={{ padding: "1px 5px" }}
                  >
                    See all
                  </p>
                </Link>
              </div>
            </div>
          </div>
        </div>
      )}
      <div
        className={`container-fluid row ${width <= 768 ? "p-0 m-0" : ""}`}
        onMouseEnter={() => setNextprev(true)}
        onMouseLeave={() => setNextprev(false)}
      >
        {width >= 768 && skipLower !== "allproducts" && (
          <div
            className="col-1 pt-5 cursor-pointer"
            style={{ marginTop: "5rem" }}
            onClick={() => handleItem("previous")}
          >
            {nextprev && (
              <img
                src={lessThan}
                className="d-block img-fluid"
                alt="Less Then"
              />
            )}
          </div>
        )}
        <div
          className={`col-12 col-md-10 mx-auto ${width <= 768 ? "p-0" : ""} `}
        >
          <div className="row flex-nowrap flex-md-wrap overflow-hidden pb-2">
            {sectionData?.map((data) => (
              <SectionCard
                data={data.product ? data.product : data}
                key={data.id}
              />
            ))}
          </div>
        </div>
        {width >= 768 && skipLower !== "allproducts" && (
          <div
            className="col-1 pt-5 cursor-pointer"
            style={{ marginTop: "5rem" }}
            onClick={() => handleItem("next")}
          >
            {nextprev && (
              <img
                src={greaterThan}
                className="d-block img-fluid"
                alt="Greater"
              />
            )}
          </div>
        )}
      </div>
    </div>
  );
}
