import Bereadcrumb from "../components/Bereadcrumb";
import { useState, useEffect} from "react";
import withCart from "../HOCs/withCart";
import Footer from "../components/Footer";
import Navbar from "../components/Navbar";
import CustomerSideBar from "../components/CustomerSideBar";
import { useWebsite } from "../context/WebsiteContext";
import { Link, useNavigate } from "react-router-dom";
import { callToast, getValueLocalStorage} from "../lib/functions"


import axios from "../lib/axiosConfig";
import {
	ErrorMessage,
	FastField,
	Field,
	FieldArray,
	Form,
	Formik,
} from "formik";
import * as Yup from "yup";

const initialValues = {
	full_name: '',
	contact_number: '',
	address: '',
}; 

 
function DeliveryAddressAdd() {
	const navigate = useNavigate();
	const { setUserInfo } = useWebsite(); 

	const [fullNameCustomError, setFullNameCustomError] = useState("");
	const [addressCustomError, setAddressCustomError] = useState("");
	const [contactNumberCustomError, setContactNumberCustomError] = useState("");

	const validationSchema = Yup.object({
		full_name: Yup.string().required("Required Name !").nullable(),
		contact_number: Yup.string().required("Required Contact Number!").nullable(), 
		address: Yup.string().required("Required Address!").nullable(),
	});

	const onSubmit = async (values, onSubmitProps) => {
		var loggedToken = getValueLocalStorage(); 

		await axios({
			method: "post",
			url: "delivery-address-add",
			headers: { Authorization: `Bearer ${loggedToken}` },
			data: values,
		})
		.then((response) => {  
			callToast('success',response.data.message); 
			setUserInfo(response.data.user);
			return navigate("/delivery-address"); 
		})
		.catch((err) => {
			setFullNameCustomError("");
			setContactNumberCustomError("");
			setAddressCustomError("");
			if (err && err.response && err.response.data.errors) {
				err.response.data.errors.forEach((item, index) => {
					if (item.code === "full_name") {
						setFullNameCustomError(item.message);
					}
					if (item.code === "contactNumber") {
						setContactNumberCustomError(item.message);
					} 
					if (item.code === "address") {
						setAddressCustomError(item.setAddressCustomError);
					} 
				});
			} 
		});
	};

	return (
		<>
			<Navbar />
			<div style={{ overflowX: "hidden" }} className="pb-3">
				<div>
					<Bereadcrumb pages={[{ path: "/cart", name: "Cart" }]} />
					<div
						className="shadow-lg mt-4 mx-3 bg-white d-flex justify-content-center"
						style={{ borderRadius: "20px" }}
					>
						<div className="row container-fluid">
							<div className="col-12 col-lg-2 px-4 ">
								<section className="mt-3">
									<CustomerSideBar />
								</section>
							</div>
							<div className="col-12 col-lg-10 py-3">
								<section>
									<div className="d-flex justify-content-between cart_orderSummary">
										<div>
											<span>
												<h6>
													<span className="px-2">
														Add Delivery Address
													</span>
												</h6>
											</span>
										</div>
									</div>
								</section>
								<div
									style={{
										background: "#faf9f9",
										borderRadius: "10px",
									}}
									className="p-2 "
								> 
									<Formik
										initialValues={initialValues}
										validationSchema={validationSchema}
										onSubmit={onSubmit}
										validateOnChange={false}
										validateOnBlur={false}
										enableReinitialize={true}
									>
										{(formik) => {
											return (
												<Form>
													<div className="mb-3">
														<label htmlFor="full_name">
															Full Name
														</label>
														<Field
															type="text"
															id="full_name"
															name="full_name"
															className="form-control"
														/>
														<ErrorMessage name="full_name">
															{(msg) => (
																<div className="error">
																	{msg}
																</div>
															)}
														</ErrorMessage>
														{fullNameCustomError !==
															"" && (
															<div className="error">
																{
																	fullNameCustomError
																}
															</div>
														)}
													</div>
													<div className="mb-3">
														<label htmlFor="contact_number">
															Contact Number
														</label>
														<Field
															type="text"
															id="contact_number"
															name="contact_number"
															className="form-control"
														/>
														<ErrorMessage name="contact_number">
															{(msg) => (
																<div className="error">
																	{msg}
																</div>
															)}
														</ErrorMessage>
														{contactNumberCustomError !==
															"" && (
															<div className="error">
																{
																	contactNumberCustomError
																}
															</div>
														)}
													</div> 
													<div className="mb-3">
														<label htmlFor="address">
															Address
														</label>
														<Field
															type="text"
															id="address"
															name="address"
															className="form-control"
														/>
														<ErrorMessage name="address">
															{(msg) => (
																<div className="error">
																	{msg}
																</div>
															)}
														</ErrorMessage>
														{addressCustomError !==
															"" && (
															<div className="error">
																{
																	addressCustomError
																}
															</div>
														)}
													</div> 
													<div className="d-grid">
														<button
															type="submit"
															className="btn btn-primary"
														>
															Update
														</button>
													</div>
												</Form>
											);
										}}
									</Formik>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<Footer />
		</>
	);
}

export default DeliveryAddressAdd;
