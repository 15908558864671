import CollectionTitle from '../components/CollectionTitle'
import LoadingSpinner from '../components/LoadingSpinner'
import Product from '../components/Product'
import Navbar from '../components/Navbar'
import Footer from '../components/Footer'
import Bereadcrumb from '../components/Bereadcrumb'
import ProductsHeadline from '../components/ProductsHeadline'
import { useParams, useLocation } from 'react-router-dom';
import InfiniteScroll from 'react-infinite-scroll-component'
import { useState, useEffect } from "react";
import axios from "../lib/axiosConfig";
import { Helmet } from "react-helmet";
import { isEmpty } from '../lib/functions'
import ReactPixel from "react-facebook-pixel";

// Initialize ReactPixel with your Pixel ID
ReactPixel.init("540109202031009");


export default function OfferProducts() {

	let { offerSlug } = useParams();

	const [page, setPage] = useState(1);
	const [loading, setLoading] = useState(true);
	const [error, setError] = useState("");
	const [products, setProducts] = useState([]);
	const [offerInfo, setOfferInfo] = useState({});
	const [pageProducts, setPageProducts] = useState([]);
	const [totalResult, setTotalResult] = useState(0);
	const [hasMore, setHasMore] = useState(true);
	const [pages, setPages] = useState([{ name: `offer`, path: `/offer` }]);

	useEffect(() => {
		ReactPixel.pageView(); // For tracking page view
	}, []);


	useEffect(() => {
		async function fetchProducts() {
			try {
				await axios({
					method: "get",
					url: "all-offer-products",
					params: {
						page: page,
						slug: offerSlug,
					}
				})
					.then((response) => {
						setProducts(preProduct => {
							return [...new Set([...preProduct, ...response.data.data.products])]
						});
						// setProducts(response.data.data);
						let offer = response.data.data.offer;
						if (!isEmpty(offer)) {
							setOfferInfo(offer);
							setPages([{ name: `${offer.name}`, path: `/${offer.name}` }]);
						}


						setTotalResult(response.data.total_result);
						setHasMore(response.data.has_more);
						setLoading(false);
						setError(false);
					})
					.catch((err) => {
						setProducts([]);
						setOfferInfo({});
						setTotalResult(0);
						setLoading(false);
						setError(err);
					});
			} catch (error) {
				console.log('Fetch data error');
			}
		}

		fetchProducts();
		return () => {
			setProducts([]);
			setOfferInfo({});
			setTotalResult(0);
			setError("");
			setLoading(true);
		};
	}, [offerSlug, page]);


	useEffect(() => {
		setPageProducts(prePageProduct => {
			return [...new Set([...prePageProduct, ...products])]
		});
	}, [products]);


	const location = useLocation();

	useEffect(() => {
		setPage(1);
		setPageProducts([]);

		return () => {
			setPageProducts([]);
			setPage(1);
		};
	}, [location]);


	return (
		<>
			<Navbar />
			{!isEmpty(offerInfo) ? (
				<>
					<Helmet>
						<title>{offerInfo.name}</title>
						<meta name="description" content={offerInfo.meta_description} />
						<meta name="keywords" content={offerInfo.meta_keywords} />
					</Helmet>
					<div className="position-relative my-4 pt-1">
						<img src={offerInfo.image_path}
							height="220px" width="100%" className="d-flex justify-content-center blurImage" alt={offerInfo.name} />
						<img src={offerInfo.image_path}
							height="270px" width="100%" className="container position-absolute top-50 start-50 translate-middle"
							alt={offerInfo.name} />
					</div>
				</>
			) : (
				<>
					<Helmet>
						<title>All  Product  </title>
					</Helmet>
				</>
			)}


			<div className="px-3">
				<CollectionTitle
					render={() => (
						<Bereadcrumb pages={pages} fontWeight="600" />
					)}
					renderInfo={() => <ProductsHeadline name={!isEmpty(offerInfo) ? offerInfo.name : "Offers"} items={totalResult} />}
				/>
				<div className="container">
					{pageProducts.length > 0 ? (
						<InfiniteScroll
							dataLength={pageProducts.length}
							hasMore={hasMore}
							next={() => setPage(page + 1)}
							loader={<LoadingSpinner />}
						>
							<div className="d-flex flex-wrap">
								{pageProducts?.map((product, pi) => (
									<Product key={pi} product={product} />
								))}
							</div>
						</InfiniteScroll>
					) : (
						<>
							{loading && !error && (<div><LoadingSpinner /></div>)}
						</>
					)}


					{!loading && pageProducts.length === 0 && (<div> No Data found</div>)}

					{loading && error && (<div> There is an error</div>)}

				</div>

			</div>
			<Footer />
		</>
	)
}
