import React, { useEffect } from 'react';
import Navbar from '../components/Navbar';
import { LazyLoadImage } from "react-lazy-load-image-component";
import MessengerCustomerChat from "react-messenger-customer-chat";
import Footer from '../components/Footer';
import { useParams } from 'react-router-dom';
import axios from 'axios';

const SingleBlog = () => {
    const { blogSlug } = useParams();

    const [blogs, setBlogs] = React.useState([]);

    console.log("blogSlug", blogSlug);

    useEffect(() => {
        async function fetchData() {
            const blogs = await axios.get(`https://web.bdbeautyglamorous.com/web-api/all-blogs-api`);
            setBlogs(blogs?.data?.blogs);
        }
        fetchData();
    }, []);

    console.log("blogs", blogs);

    const blog = blogs?.find((blog) => blog?.slug === blogSlug);
    console.log("blog", blog);

    return (
        <div>
            <Navbar LazyLoadImage={LazyLoadImage} />
            <div>
                <div className="row " style={{ marginTop: "50px", marginBottom: "50px" }}>
                    <div className="col-lg-6 col-md-8 col-12 mx-auto">
                        <img src={blog?.image_path} className='img-fluid' alt="" />

                        <h1 className="text-center mt-4 mb-3">{blog?.title}</h1>
                        <p>{blog?.short_detail}</p>
                    </div>
                </div>
            </div>
            <MessengerCustomerChat
                pageId=""
                appId=""
            />
            <Footer />
        </div>
    );
};

export default SingleBlog;