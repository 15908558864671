import React from "react";
import Nav from "react-bootstrap/Nav";
import {NavLink} from 'react-router-dom'


const CustomerSideBar = () => {
	return (
		<>
			<Nav defaultActiveKey="/profile" className="flex-column">
				<NavLink to='/profile' className="nav-link"> Profile </NavLink>
				<NavLink to='/delivery-address' className="nav-link"> Delivery Address </NavLink>
				<NavLink to='/orders' className="nav-link"> Order List </NavLink>
				<NavLink to='/cart' className="nav-link"> Cart </NavLink>
				<NavLink to='/wishlist' className="nav-link"> Wishlist </NavLink>
			</Nav>
		</>
	);
};

export default CustomerSideBar;
