import { BiUpArrow } from "react-icons/bi";
import { BiDownArrow } from "react-icons/bi";
import { Link } from "react-router-dom";
import Footer from "../components/Footer";
import Navbar from "../components/Navbar";
import MBNavbar from "../components/MBNavbar";
import MBFooter from "../components/MBFooter";
import useWidth from "../hooks/useWidth";
import { useWebsite } from "../context/WebsiteContext";
import { useState } from "react";

export default function Categories() {
  const width = useWidth();
  const { categories } = useWebsite();

  const [isOpen, setIsOpen] = useState(false);
  const [categoryId, setCategoryId] = useState("");

  const toggleCategory = (index) => {
    setIsOpen(!isOpen);
    setCategoryId(categoryId !== "" ? "" : index);
  };

  return (
    <>
      {width >= 768 ? <Navbar /> : <MBNavbar searchButton={false} />}

      {/* <div style={{ paddingBottom: 70 }}>
				{(categories.length > 0) && categories?.map((category,index) => (
					<div key={index}>
						<Link to={`/${category.slug}`}>
							<div className="pt-1 px-1 position-relative text-white">
								<img src={category.image_path} width="100%" className="rounded-3" alt='' />
								<div
									className="fs-4 position-absolute categoriesText"
									style={{ zIndex: '1', top: 0 }}
								>
									<div className="d-flex justify-content-start px-2 text-uppercase">
										{category.name}
									</div>
								</div>
							</div>
						</Link>
					</div>
				))}
			</div> */}
      <div className="mt-4" style={{ paddingBottom: "70px" }}>
        {width <= 768 &&
          categories.length > 0 &&
          categories?.map((category, index) => (
            <>
              <div className="d-flex justify-content-between align-items-center mb-3">
                <div
                  className="d-flex justify-content-start px-2 text-uppercase cursor-pointer"
                  onClick={() => {
                    toggleCategory(index);
                  }}
                >
                  <h4 style={{ fontSize: "16px" }}>{category.name}</h4>
                  {categoryId === index ? <BiUpArrow /> : <BiDownArrow />}
                </div>
                <div>
                  <Link to={`/${category?.slug}`}>
                    <img
                      src={category.image_path}
                      width="100%"
                      className="rounded-3"
                      alt=""
                      style={{
                        width: "80px",
                        height: "60px",
                        objectFit: "cover",
                      }}
                    />
                  </Link>
                </div>
              </div>
              {categoryId === index &&
                (() => {
                  const category = categories.find(
                    (category, index) => index === categoryId
                  );

                  if (category) {
                    return (
                      <div className="ms-5">
                        {category?.sub_categories?.map((subCategory, index) => (
                          <>
                            <Link
                              to={`/${category?.slug}/${subCategory?.slug}`}
                              className="d-flex flex-col text-black mb-1"
                            >
                              {subCategory?.name}
                            </Link>
                          </>
                        ))}
                      </div>
                    );
                  } else {
                    return null;
                  }
                })()}
            </>
          ))}
      </div>

      {width >= 768 ? <Footer /> : <MBFooter />}
    </>
  );
}
