import { MdSmsFailed } from "react-icons/md"; 
// Example of PaymentSuccess component
import { Link, useParams } from 'react-router-dom';
import useWidth from '../hooks/useWidth';
import MBNavbar from '../components/MBNavbar';
import Footer from '../components/Footer';
import MBFooter from '../components/MBFooter';
import Navbar from '../components/Navbar';
import { LazyLoadImage } from "react-lazy-load-image-component";

const PaymentFailure = () => {
    const { transaction } = useParams();
    const width = useWidth();
    return (
        <>
            {
                width <= 768 ? (
                    <>
                        <MBNavbar searchIcon={false} />
                        <div class="container">
                            <div class="row justify-content-center">
                                <div class="col-md-5">
                                    <div class="message-box _success">
                                        {/* <i class="fa fa-check-circle" aria-hidden="true"></i> */}
                                        <MdSmsFailed size={60} />
                                        <h2> Your payment failed </h2>
                                        <Link to="/">
                                            <button className='otpButton mt-3' >Back to Home</button>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                            <hr />

                        </div>
                        <Footer />
                        <MBFooter />
                    </>
                ) : (
                    <>
                        <Navbar LazyLoadImage={LazyLoadImage} />
                        <div class="container">
                            <div class="row justify-content-center">
                                <div class="col-md-5">
                                    <div class="message-box _success">
                                        {/* <i class="fa fa-check-circle" aria-hidden="true"></i> */}
                                        <MdSmsFailed size={60} />
                                        <h2> Your payment failed </h2>
                                        <Link to="/" className='mt-4'>
                                            <button className='otpButton mt-3' >Back to Home</button>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                            <hr />
                        </div>

                        <Footer />
                    </>
                )}
        </>
    );
};

export default PaymentFailure;