import { Link } from "react-router-dom";
import { MdDelete } from "react-icons/md";
import { useState } from "react";
import { numberWithSpaces, callToast, getValueLocalStorage } from "./../lib/functions";
import axios from "../lib/axiosConfig";
import { useWebsite } from "../context/WebsiteContext";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { toast } from "react-toastify";
import { useCart } from "../context/CartContext";

export default function CartProduct({ data, mobile }) {
  const {
    cartDispatch,
    isLoggedInState,
    setTotalCartQuantity,
    setTotalCartAmount,
  } = useWebsite();

  const { carts, handleUpdateCartQuantity, handleRemoveFromCart } = useCart();

  const [item, setItem] = useState(data.quantity);
  const addItem = () => setItem((item) => item + 1);
  const removeItem = () => setItem((item) => item - 1);

  const updateProductQuantity = async (type) => {
    var loggedToken = getValueLocalStorage();
    var quantity = type === "increment" ? 1 : -1;
    if (loggedToken) {
      try {
        await axios({
          method: "post",
          url: "update-cart",
          headers: { Authorization: `Bearer ${loggedToken}` },
          data: {
            cart_id: data.id,
            quantity: quantity,
          },
        })
          .then((response) => {
            cartDispatch({
              type: "SET_CART",
              payload: response.data.cart.cartProducts,
            });
            setTotalCartQuantity(response.data.cart.cartProductQuantity);
            setTotalCartAmount(response.data.cart.cartProductAmount);
          })
          .catch((err) => {
            callToast("warn", "Product Update into Error");
          });
      } catch (error) {
        callToast("warn", "Product Update into Error");
      }
    } else {
      handleUpdateCartQuantity(data?.product, type);
      // const updatedCartItems = cartItems?.map((item) => {
      //   if (item?.product?.id === data?.product?.id) {
      //     if (item?.quantity > 1 || type === "increment") {
      //       toast.success("Product quantity updated successfully");
      //       return { ...item, quantity: item.quantity + quantity };
      //     }
      //     else {
      //       toast.error("Quantity can't be less than 1");
      //     }
      //   }
      //   return item;
      // });
      // // setCartItems(updatedCartItems);
      // localStorage.setItem("cartItem", JSON.stringify(updatedCartItems));
    }

  };

  let cartItems = localStorage.getItem("cartItem");
  if (cartItems) {
    try {
      cartItems = JSON.parse(cartItems);
    } catch (error) {
      console.error("Error parsing cart items:", error);
      cartItems = [];
    }
  } else {
    cartItems = [];
  }

  const removeProductQuantity = async (type) => {
    var loggedToken = getValueLocalStorage("loggedToken");
    if (loggedToken) {
      if (window.confirm("Are you sure to remove it?")) {
        // var loggedToken = getValueLocalStorage();
        try {
          await axios({
            method: "post",
            url: "remove-from-cart",
            headers: { Authorization: `Bearer ${loggedToken}` },
            data: {
              cart_id: data.id,
            },
          })
            .then((response) => {
              cartDispatch({
                type: "SET_CART",
                payload: response.data.cart.cartProducts,
              });
              setTotalCartQuantity(response.data.cart.cartProductQuantity);
              setTotalCartAmount(response.data.cart.cartProductAmount);
            })
            .catch((err) => {
              callToast("warn", "Product Delete into Error");
            });
        } catch (error) {
          callToast("warn", "Product Delete into Error");
        }
      }
    } else {
      if (window.confirm("Are you sure to remove it?")) {
        handleRemoveFromCart(data?.product)
        // const updatedCartItems = cartItems.filter(item => item?.product?.id !== data?.product?.id);
        // // setCartItems(updatedCartItems);
        // localStorage.setItem("cartItem", JSON.stringify(updatedCartItems));
        // callToast("success", "Product removed from cart");
      }

    }

  };

  return mobile ? (
    <div className="mb-2">
      <div>
        <div className="d-flex bg-white">
          <Link to={`/product/${data?.product?.slug}`}>
            <div
              className="px-2 py-2 d-flex align-items-center"
              style={{ width: "80px" }}
            >
              <img
                src={data?.product?.image_path}
                className="cursor img-fluid d-block"
                width="100%"
                height="100%"
                alt={data?.title}
              />
            </div>
          </Link>
          <div className="flex-grow-1 font-weight-bold pt-1 position-relative">
            <div className="cartProdFlex">
              <div>
                <Link
                  to={`/product/${data?.product?.slug}`}
                  style={{ color: "black" }}
                >
                  <div className="pt-1">
                    <div
                      className="d-inline-block text-truncate"
                      style={{
                        maxWidth: "18rem",
                        fontSize: "14px",
                        fontWeight: 550,
                      }}
                    >
                      {data?.product?.name}
                    </div>
                  </div>
                </Link>
              </div>
              <div>
                <Link
                  to={`/product/${data?.product?.slug}`}
                  style={{ color: "black" }}
                >
                  <div className="text-muted" style={{ fontSize: "12px" }}>
                    {data?.product?.name}
                  </div>
                </Link>
              </div>
              <div>
                <Link
                  to={`/product/${data?.product?.slug}`}
                  style={{ color: "black" }}
                >
                  <div
                    className="d-flex text-secondary justify-content-between "
                    style={{ fontSize: "12px" }}
                  >
                    <div className="d-flex justify-content-start">
                      <div className={"px-2"}>
                        TK. {parseInt(data?.quantity) * parseInt(data?.price)}
                      </div>
                    </div>
                  </div>
                </Link>
              </div>
              <div className="mt-0 py-1">
                <div className="d-flex justify-content-between">
                  <button
                    type="button"
                    className="bg-transparent border-0 m-0 p-0"
                    onClick={removeProductQuantity}
                    id={data.id}
                  >
                    <MdDelete fontSize={24} color="rgb(108, 117, 125)" />
                  </button>
                  <div
                    className="border d-flex justify-content-end mx-2"
                    style={{
                      fontSize: "14px",
                      fontWeight: 510,
                      color: "rgb(107, 112, 92)",
                    }}
                  >
                    <button
                      type="button"
                      className="bg-transparent border-0 m-0 p-0"
                      id={data?.id}
                      onClick={() => updateProductQuantity("decrement")}
                    >
                      <span className="px-2">-</span>
                    </button>
                    <span
                      className="px-2 "
                      style={{
                        backgroundColor: "rgb(234, 234, 234)",
                      }}
                    >
                      {data?.quantity}
                    </span>
                    <button
                      type="button"
                      className="bg-transparent border-0 m-0 p-0"
                      onClick={() => updateProductQuantity("increment")}
                    >
                      <span className="px-2">+</span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  ) : (
    <div className="py-2">
      <div className="row">
        <div className="col-7">
          <div className="row">
            <div className="col-3">
              <div>
                <Link to={`/product/${data?.product?.slug}`}>
                  <img
                    src={data?.product?.image_path}
                    width={90}
                    height={90}
                    className="rounded shadow-sm img-fluid d-block h-90"
                    alt=""
                  />
                </Link>
              </div>
            </div>
            <div className="col-9 mt-3 cart_productTitle">
              <div className="d-flex flex-column justify-content-between">
                <div className=" ">
                  <Link
                    to={`/product/${data?.product?.slug}`}
                    style={{
                      textDecoration: "none",
                      color: "rgb(87, 85, 85)",
                    }}
                  >
                    {data?.product?.name}
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-5 ">
          <div className="d-flex justify-content-around mt-4">
            <div
              onClick={removeProductQuantity}
              id={data?.id}
              className="cursor-pointer"
            >
              <MdDelete fontSize={16} color="#6c757d" />
            </div>
            <div className="d-flex justify-content-around ">
              <div
                className="cursor cart_qtyActive  position-relative mt-1"
                id={data?.id}
                onClick={() => updateProductQuantity("decrement")}
              >
                <span className="position-absolute item-decreament-button">
                  -
                </span>
              </div>
              <div className="px-2 ">
                <span style={{ fontSize: "12px" }}>{data?.quantity}</span>
              </div>
              <div
                className="cursor cart_qtyActive  position-relative mt-1 "
                onClick={() => updateProductQuantity("increment")}
              >
                <span className="position-absolute item-increament-button">
                  +
                </span>
              </div>
            </div>
            <div
              style={{
                display: "inline-block",
                fontSize: "12px",
              }}
              className="pt-1"
            >
              <span>{data?.quantity}</span>
              <span className="px-1">x</span>
              <span>TK. {data?.price}</span>
              <span className="px-1">=</span>
              <span>{parseInt(data?.quantity) * parseInt(data?.price)}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
