import { FacebookIcon, FacebookShareButton, LinkedinIcon, LinkedinShareButton, TelegramIcon, TelegramShareButton, TumblrIcon, TumblrShareButton, TwitterIcon, TwitterShareButton, WhatsappIcon, WhatsappShareButton } from 'react-share';
// import { SocialIcon } from 'react-social-icons'

// const platforms = [
// 	{
// 		id: 985049858,
// 		path: 'https://whatsapp.com',
// 	},
// 	{
// 		id: 93840938,
// 		path: 'https://facebook.com',
// 	},
// 	{
// 		id: 34898309,
// 		path: 'https://twitter.com',
// 	},
// 	{
// 		id: 2908349,
// 		path: 'https://tumblr.com',
// 	},
// 	{
// 		id: 894985948,
// 		path: 'https://linkedin.com',
// 	},
// ]

export default function SocialShare({ pageTitle }) {

	// const currentUrl = window.location.href;
	const currentUrl = "https://bdbeautyglamorous.com";

	// const style = {
	// 	borderRadius: 3,
	// 	border: 0,
	// 	color: 'white',
	// 	padding: '0 1px',
	// 	height: 35,
	// 	width: 35,
	//   };

	return (
		<div className="social-icon-wrap">
			{/* {platforms?.map(platform => (
				<SocialIcon style={{ width: 22, height: 22 }} url={platform.path} key={platform.id} />
			))} */}

			{/* <FacebookShareButton url={currentUrl} quote={pageTitle}>
				<SocialIcon network="facebook" url='https://whatsapp.com' target='_blank' style={style}/>
			</FacebookShareButton> */}


			<FacebookShareButton url={currentUrl} quote={pageTitle} style={{ padding: 5 }}>
				<FacebookIcon size={35} round={true} />
			</FacebookShareButton>
			<WhatsappShareButton url={currentUrl} quote={pageTitle} style={{ padding: 5 }}>
				<WhatsappIcon size={35} round={true} />
			</WhatsappShareButton>
			{/* <TwitterShareButton url={currentUrl} quote={pageTitle} style={{ padding: 5 }}>
				<TwitterIcon size={35} round={true} />
			</TwitterShareButton> */}
			<LinkedinShareButton url={currentUrl} quote={pageTitle} style={{ padding: 5 }}>
				<LinkedinIcon size={35} round={true} />
			</LinkedinShareButton>
			<TelegramShareButton url={currentUrl} quote={pageTitle} style={{ padding: 5 }}>
				<TelegramIcon size={35} round={true} />
			</TelegramShareButton>
			{/* <TumblrShareButton url={currentUrl} quote={pageTitle} style={{ padding: 5 }}>
				<TumblrIcon size={35} round={true} />
			</TumblrShareButton> */}
		</div>
	)
}
