import { AiOutlineMenu } from "react-icons/ai";
import { MdFavoriteBorder, MdSearch, MdLocalMall } from "react-icons/md";
import { useState } from "react";
import MBSidebar from "./MBSidebar";
import TopNotification from "./TopNotification";
import logo from "../images/logo1x1.jpg";
import menuWhite from "../images/menuWhite.svg";
import { Link } from "react-router-dom";
import { useWebsite } from "../context/WebsiteContext";
import { isEmpty } from "../lib/functions";
import wishlist from "../images/Heart.png";
import cart from "../images/Cart.png";
import { useCart } from "../context/CartContext";
import { useWishlist } from "../context/WishlistContext";

export default function MBNavbar({
  searchIcon,
  searchButton,
  cartIcon,
  title,
}) {
  const [sidebar, setSidebar] = useState(false);
  const toggleSidebar = () => setSidebar(!sidebar);
  const { carts, handleAddToCart } = useCart();
  const { wishlists, handleAddToWishlist } = useWishlist();

  const {
    websiteInfo,
    wishlistCount,
    isLoggedInState,
    setIsLoggedInState,
    totalCartQuantity,
    setTotalCartQuantity,
  } = useWebsite();

  return (
    <header style={{ position: "sticky", top: 0, zIndex: 1020 }}>
      <div>
        <div className="text-center">
          <div className="">
            {/* <TopNotification mobile /> */}
            <div
              className={`d-flex justify-content-between px-1 shadow ${!title ? "py-2" : ""
                }`}
              style={{ background: "white" }}
            >
              <div className="my-1">
                <div className="mobMenu">
                  <button
                    className="btn"
                    tabIndex={0}
                    type="button"
                    onClick={toggleSidebar}
                  >
                    {/* <img src={menuWhite} width={24} height={24} alt="" /> */}
                    <AiOutlineMenu style={{ fontSize: "28px" }} />
                  </button>
                </div>
              </div>
              {!isEmpty(websiteInfo) ? (
                <div className="px-4 py-1">
                  <Link to="/">
                    <img
                      src={websiteInfo.photo_path}
                      alt={websiteInfo.name}
                      width="120"
                      height="40px"
                      className="rounded-circle"
                    />
                  </Link>
                </div>
              ) : (
                <div className="flex-grow-1 px-2 text-start align-self-center overflow-hidden">
                  <div>
                    <h6 className="text-white mb-0 text-ellipsis">
                      BD Beauty Glamorous
                    </h6>
                  </div>
                </div>
              )}

              <div className="d-flex justify-content-between   pt-1">
                {searchIcon !== false && (
                  <div className="px-1 my-1">
                    <Link to="/search">
                      <MdSearch fontSize={25} color="black" />
                    </Link>
                  </div>
                )}
                <div className="my-1">
                  {/* <Link to="/wishlist" className="text-decoration-none color-black">
										{ (wishlistCount && wishlistCount > 0)? (
											<span className="position-absolute text-center productCartCount collectionOfferPosition">
												<span className="px-1">{ wishlistCount }</span> 
											</span>
										) : ""}
									</Link> */}
                  <Link to="/wishlist">
                    <div className="d-flex justify-content-end float-end position-relative">
                      <div className="position-relative">
                        {/* <MdFavoriteBorder color="black" fontSize={25} /> */}
                        <img src={wishlist} alt="" style={{ width: "30px" }} />
                      </div>
                      {
                        wishlists?.length > 0 ? (
                          <span className="position-absolute text-center productCartCount collectionOfferPosition">
                            <span className="px-1">{wishlists?.length}</span>
                          </span>

                        ) : (
                          wishlistCount && wishlistCount > 0 ? (
                            <span className="position-absolute text-center productCartCount collectionOfferExist">
                              <span className="px-1">{wishlistCount}</span>
                            </span>
                          ) : (
                            ""
                          )
                        )
                      }
                    </div>
                  </Link>
                </div>
                <div className="ps-2">
                  <Link to="/cart">
                    <div className="d-flex justify-content-end float-end position-relative">
                      <div className="position-relative">
                        {/* <MdLocalMall fontSize={25} color="black" /> */}
                        <img src={cart} alt="" style={{ width: "30px" }} />
                      </div>
                      {
                        carts?.length > 0 ? (
                          <span className="position-absolute text-center productCartCount collectionOfferPosition">
                            <span className="px-1">{carts?.length}</span>
                          </span>
                        ) : (
                          totalCartQuantity && totalCartQuantity > 0 ? (
                            <span className="position-absolute text-center productCartCount collectionOfferExist">
                              <span className="px-1">{totalCartQuantity}</span>
                            </span>
                          ) : (
                            ""
                          )
                        )
                      }
                    </div>
                  </Link>
                </div>
              </div>
            </div>
            {searchButton !== false && (
              <div>
                <div className="px-2 py-2" style={{ background: "#F5F5F5" }}>
                  <Link to="/search">
                    <div className="py-1 border d-flex justify-content-between text-muted border">
                      <div className="px-2">Search for products...</div>
                      <div className="px-1">
                        <MdSearch fontSize={24} />
                      </div>
                    </div>
                  </Link>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <MBSidebar show={sidebar} handleShow={toggleSidebar} />
    </header>
  );
}
